import { CHAR } from '../../constants';

export default {
  assignment: {
    awaitingTransportRequests: 'En attente',
    exclusion: {
      error: 'Le statut de la demande a changé, veuillez rafraichir la page du navigateur',
      tooltip: 'Cette demande de transport est exclue de l’assignation assistée',
    },
    map: {
      removeSelection: 'Effacer la sélection',
      unpositionedTeams: {
        title: 'Véhicule(s) sans position GPS',
        hiddenTeam: 'autre véhicule',
        hiddenTeams: 'autres véhicules',
      },
    },
    table: {
      abbreviatedMinutes: 'min',
      anticipatedTeam: 'Équipe anticipée',
      assignmentTableIsEmpty: 'Aucune demande en attente',
      awaitingTime: 'Attente',
      buttons: {
        allocate: {
          allocate: 'Répartir',
        },
        reassign: {
          cancel: 'Annuler',
        },
      },
      distanceDuration: 'Distance Durée',
      dispatchTableIsEmpty: 'Aucune répartition à effectuer',
      duration: 'durée',
      filter: {
        placeholder: 'Masquer les demandes par statut',
      },
      from: 'De',
      originAndDestination: 'Provenance Destination',
      modals: {
        reassign: {
          cancelAssignment: 'Annuler l’assignation',
          goBack: 'Revenir en arrière',
          message:
            'Êtes-vous sûr de vouloir réassigner la demande {requestNumber} à l’équipe {teamNumber}? Cette action est irréversible.',
          reassign: 'Réassigner',
        },
      },
      notification: {
        assignmentConfirmation: {
          description: 'Le trajet {formattedTransportId} (#{transportId}) a été assigné à l’équipe {teamNumber}',
          title: 'Confirmation d’assignation',
        },
      },
      route: 'Trajet',
      team: 'Équipe',
      to: 'À',
      passengers_seatbelts: 'Passagers Ceintures',
    },
    title: 'Assignation assistée',
    transportRequestsToDispatchAndOnARide: 'À répartir et en raccompagnement',
  },
  map: {
    placeholder: {
      baseNumber: 'Numéro(s) de base',
      client: 'Demandeur',
      evening: {
        number: 'Numéro de soirée',
      },
      team: {
        number: 'Numéro d’équipe',
      },
      transportRequestId: 'Numéro de la demande',
    },
    select: {
      institution: 'Établissement',
      location: 'Centrale d’origine',
      teamStatus: 'Sélectionnez un statut d’équipe',
      transportStatus: 'Sélectionnez un statut de demande',
    },
    title: 'Carte',
    autoZoom: 'Zoom automatique',
  },
  name: 'Français',
  team: {
    base: 'Base #',
    byId: `${CHAR.NBSP}id=`,
    byNumber: `${CHAR.NBSP}#`,
    driver: `Conducteur${CHAR.NNBSP}:`,
    escort: `Escorte motorisée${CHAR.NNBSP}:`,
    infoWindow: {
      assignedTo: {
        label: 'Assignée à',
        none: 'Aucun',
      },
      assistedAssignment: {
        exclude: 'Exclure de l’assignation assistée',
        include: 'Inclure dans l’assignation assistée',
        exclusionModal: {
          closeLabel: 'Annuler',
          confirmLabel: 'Confirmer',
          titlePrefix: 'Exclure l’équipe',
          titleSuffix: 'de l’assignation assistée?',
        },
      },
      motorType: {
        label: 'Type de moteur',
        gas: 'Essence',
        electric: 'Électrique',
      },
      status: 'Statut',
      team: 'Équipe',
      transmission: {
        label: 'Transmission',
        automatic: 'Automatique',
        manual: 'Manuelle',
      },
      unknown: 'N/D',
      vip: {
        label: 'VIP',
        yes: 'oui',
        no: 'non',
      },
      waitTime: 'Temps d’attente',
    },
    members: 'Membres',
    mobile: `Cellulaire${CHAR.NNBSP}:`,
    page: 'Voir l’',
    partner: `Partenaire${CHAR.NNBSP}:`,
    radio: `Radio #${CHAR.NNBSP}:`,
    standard: 'équipe',
    status: {
      NOT_DISSOLVED: 'Toutes les équipes',
      ASSIGNED: 'En raccompagnement',
      AVAILABLE_AT_COORDINATES: 'Disponible aux coordonnées',
      AVAILABLE_IN_INSTITUTION: 'Disponible dans un établissement',
      AVAILABLE_IN_LOCATION: 'Disponible dans une centrale/satellite',
      AVAILABLE_IN_REGION: 'Disponible dans une région',
      AVAILABLE: 'Disponible',
      DISSOLVED: 'Dissoute',
      IN_RETURN: 'En retour',
      ON_BREAK: 'En pause',
      tooltip: {
        NOT_DISSOLVED: 'Toutes les équipes',
        ASSIGNED: 'En raccompagnement',
        AVAILABLE_AT_COORDINATES: 'Disponible',
        AVAILABLE_IN_INSTITUTION: 'Disponible',
        AVAILABLE_IN_LOCATION: 'Disponible',
        AVAILABLE_IN_REGION: 'Disponible',
        AVAILABLE: 'Disponible',
        DISSOLVED: 'Dissoute',
        IN_RETURN: 'En retour',
        ON_BREAK: 'En pause',
      },
    },
    vip: 'équipe V.I.P.',
  },
  transport: {
    destination: `Destination${CHAR.NNBSP}:`,
    infoWindow: {
      assistedAssignment: {
        exclude: 'Exclure de l’assignation assistée',
        include: 'Inclure dans l’assignation assistée',
        exclusionModal: {
          closeLabel: 'Annuler',
          confirmLabel: 'Confirmer',
          titlePrefix: 'Exclure la demande de transport',
          titleSuffix: 'de l’assignation assistée?',
        },
      },
      client: 'Demandeur',
      destination: 'Destination',
      origin: 'Provenance',
      passengerCount: 'Nombre passagers',
      smsSent: {
        label: 'SMS d’assignation envoyé',
        no: 'Non',
        yes: 'Oui',
      },
      team: 'Équipe',
      unassigned: 'Aucune',
      unknown: 'N/D',
      vehicle: 'Véhicule',
      waitTime: 'Attente',
    },
    origin: `Provenance${CHAR.NNBSP}:`,
    page: 'Voir la demande de transport #',
    requester: `Demandeur${CHAR.NNBSP}:`,
    status: {
      ALLOCATED: 'Répartie',
      ALL_OPEN_REQUESTS: 'Toutes les demandes ouvertes',
      ASSIGNED: 'Assignée',
      CANCELLED: 'Annulée',
      COMPLETED: 'Complétée',
      ON_BOARD: 'Client pris en charge',
      PENDING: 'En attente',
      TRANSFERRED: 'Transférée',
      UNFINDABLE: 'Introuvable',
    },
    vehicle: `Véhicule${CHAR.NNBSP}:`,
  },
  originAndDestination: {
    placesToggle: 'Recherche via Google Maps',
    route: 'Trajet',
    origin: 'Provenance',
    destination: 'Destination',
    institution: `Établissement${CHAR.NNBSP}:`,
    emptyMessage: 'Veuillez saisir au moins un caractère.',
    placeholder: 'Résidence ou autre établissement',
  },
  orientation: {
    EAST: 'Est',
    NORTH: 'Nord',
    SOUTH: 'Sud',
    WEST: 'Ouest',
  },
  place: {
    enterOrigin: 'Entrez une position de départ',
    enterDestination: 'Entrez une position d’arrivée',
  },
  vehicle: {
    color: {
      beige: 'Beige',
      black: 'Noir',
      blue: 'Bleu',
      brown: 'Brun',
      green: 'Vert',
      grey: 'Gris',
      orange: 'Orange',
      other: 'Autre',
      pink: 'Rose',
      purple: 'Violet',
      red: 'Rouge',
      silver: 'Argent',
      white: 'Blanc',
      yellow: 'Jaune',
    },
  },
};
