<template>
  <n-auto-complete
    v-model:value="search"
    :options="filteredOptions"
    :placeholder="placeholder"
    clearable
    :on-select="setModel"
    :on-update:value="updateSearch"
    :on-blur="checkModelNotEmpty"
  />
</template>

<script>
import { defineComponent, ref, computed, toRef } from 'vue';
import { NAutoComplete } from 'naive-ui';
import _isNil from 'lodash/isNil';

export default defineComponent({
  name: 'OnrAutoComplete',
  components: { NAutoComplete },
  props: {
    options: { type: Array, required: true },
    value: { type: [String, Number], default: () => null },
    placeholder: { type: String, default: () => '' },
    maxSuggestions: { type: Number, default: 250 },
  },
  emits: ['update:value'],
  setup(props) {
    const searchRef = ref('');

    const options = toRef(props, 'options');

    const filteredOptions = computed(() => {
      if (!searchRef.value) {
        return [];
      }
      const reg = new RegExp(searchRef.value, 'iu');
      return options.value
        .filter((option) => reg.test(option.name))
        .slice(0, props.maxSuggestions)
        .map((option) => ({
          label: option.name,
          value: option.id,
        }));
    });

    return {
      search: searchRef,
      filteredOptions,
    };
  },
  methods: {
    setModel(value) {
      this.$emit('update:value', value);
    },
    updateSearch(value) {
      if (!value) {
        // User has clicked clear button or selected all characters and hit backspace:
        this.search = null;
        this.$emit('update:value', undefined);
      } else if (value.length === 1) {
        // User has replaced value by selecting all characters and entered one letter:
        this.search = value;
        this.$emit('update:value', undefined);
      } else if (_isNil(this.value)) {
        // User can type to search if no model is already set:
        this.search = value;
      }
    },
    checkModelNotEmpty(event) {
      if (!event.srcElement.value) {
        this.$emit('update:value', undefined);
      } else if (_isNil(this.value)) {
        this.search = null;
      }
    },
  },
});
</script>
