import { defineComponent } from 'vue';
import { CHAR } from '../../constants';
import TransportRow from './TransportRow.vue';
export default defineComponent({
    components: { TransportRow: TransportRow },
    props: {
        transports: {
            type: Array,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: function () { return false; },
        },
        timestamp: {
            type: Number,
            default: function () { return null; },
        },
    },
    emits: ['transport:inspect', 'team:inspect'],
    setup: function (_, _a) {
        var emit = _a.emit;
        function onInspectTransport(transport) {
            emit('transport:inspect', transport);
        }
        function onInspectTeam(team) {
            emit('team:inspect', team);
        }
        return {
            CHAR: CHAR,
            onInspectTransport: onInspectTransport,
            onInspectTeam: onInspectTeam,
        };
    },
});
