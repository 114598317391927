/* istanbul ignore file */

export const ALL_OPEN_REQUESTS = 'ALL_OPEN_REQUESTS';

const TransportStatus = {
  ALL_OPEN_REQUESTS,
  ALLOCATED: 'ALLOCATED',
  ASSIGNED: 'ASSIGNED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  ON_BOARD: 'ON_BOARD',
  PENDING: 'PENDING',
  TRANSFERRED: 'TRANSFERRED',
  UNFINDABLE: 'UNFINDABLE',
};

export default TransportStatus;
