// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.separator[data-v-5356d8bf] {
  height: 1px;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/map/infoWindows/MarkerInfoWindow.vue"],"names":[],"mappings":";AAeA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":["<template>\n  <div class=\"info-window\">\n    <slot name=\"header\"></slot>\n\n    <div class=\"separator\"></div>\n\n    <slot name=\"content\"></slot>\n\n    <div class=\"info-window-footer\">\n      <slot name=\"footer\"></slot>\n    </div>\n  </div>\n</template>\n\n<style scoped>\n.separator {\n  height: 1px;\n  margin-top: 15px;\n  margin-bottom: 10px;\n  background-color: lightgray;\n}\n</style>\n\n<style>\n.info-window {\n  width: 100%;\n}\n\n.info-window-header {\n  display: flex;\n  align-items: center;\n}\n\n.info-window-title-wrapper {\n  font-size: 17px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.info-window-hyperlink {\n  text-decoration: underline;\n}\n\n.info-window-hyperlink:focus {\n  outline: none;\n}\n\n.info-window-hyperlink:hover {\n  font-weight: bold;\n}\n\n.info-window-row {\n  margin-left: 5px;\n  margin-bottom: 5px;\n}\n\n.info-window-footer {\n  margin-top: 10px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
