// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pad-wrapper[data-v-16b88354] {
  padding: 0 25px;
  margin-top: 3rem;
}
.title[data-v-16b88354] {
  padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/map/MapHeader.vue"],"names":[],"mappings":";AAwBA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,oBAAoB;AACtB","sourcesContent":["<template>\n  <div class=\"pad-wrapper\">\n    <div class=\"nez-rouge section row-fluid\">\n      <h3 class=\"title\">\n        {{ t('map.title') }}\n      </h3>\n    </div>\n  </div>\n</template>\n\n<script>\nimport { useI18n } from 'vue-i18n';\n\nexport default {\n  setup() {\n    const { t } = useI18n({ useScope: 'global' });\n    return {\n      t,\n    };\n  },\n};\n</script>\n\n<style scoped>\n.pad-wrapper {\n  padding: 0 25px;\n  margin-top: 3rem;\n}\n.title {\n  padding-bottom: 10px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
