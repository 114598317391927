<template>
  <div class="weight-normal" style="min-width: 32ch">
    <div class="address-group info-window-address-div">
      <div class="title">
        <TeamLabel :team="team" />
        <template v-if="team.radioBase">
          <span class="dimmed">{{ emDash }}</span>
          <span>{{ $t('team.base') }}</span>
          <span class="weight-normal">{{ team.radioBase }}</span>
        </template>
        <span class="dimmed">{{ emDash }}</span>
        <span class="weight-normal">{{ $t(`team.status.${team.status}`) }}</span>
      </div>
    </div>

    <div v-if="team.usemobilephone" class="address-group">
      <div class="title">{{ $t('team.mobile') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ team.mobilePhone }}</div>
      </div>
    </div>

    <div v-if="team.useRadio || team.useAmateurRadio" class="address-group">
      <div class="title">{{ $t('team.radio') }}</div>
      <div class="address">{{ team.amateurRadioBase }}</div>
    </div>

    <div v-if="driverFullName" class="address-group">
      <div class="title">{{ $t('team.driver') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ driverFullName }}</div>
      </div>
    </div>

    <div v-if="escortFullName" class="address-group">
      <div class="title">{{ $t('team.escort') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ escortFullName }}</div>
      </div>
    </div>

    <div v-if="partnerFullName" class="address-group">
      <div class="title">{{ $t('team.partner') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ partnerFullName }}</div>
      </div>
    </div>

    <div class="address-group view-link">
      <a :href="`team/${team.id}`" target="_blank" class="no-outline">
        <span>{{ $t('team.page') }}<TeamLabel :team="team" /></span>
      </a>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { CHAR } from '../../constants';
import TeamLabel from './TeamLabel.vue';
import { formatName } from '../../helpers/Formatters';

export default defineComponent({
  components: { TeamLabel },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const driverFullName = computed(() => {
      if (!props.team.driver) {
        return null;
      }
      return formatName(props.team.driver.volunteer.firstName, props.team.driver.volunteer.lastName);
    });
    const escortFullName = computed(() => {
      if (!props.team.escort) {
        return null;
      }
      return formatName(props.team.escort.volunteer.firstName, props.team.escort.volunteer.lastName);
    });
    const partnerFullName = computed(() => {
      if (!props.team.partner) {
        return null;
      }
      return formatName(props.team.partner.volunteer.firstName, props.team.partner.volunteer.lastName);
    });

    return {
      driverFullName,
      escortFullName,
      partnerFullName,
      emDash: `${CHAR.NBSP}${CHAR.EM_DASH}${CHAR.NBSP}`,
    };
  },
});
</script>

<style scoped>
.no-outline:focus {
  outline: none;
}
.address-group {
  margin-bottom: 0.5rem;
}
.weight-normal {
  font-weight: 400;
}
.dimmed {
  opacity: 0.3333;
}
.title {
  font-weight: 500;
}
.title:first-letter {
  text-transform: uppercase;
}
</style>
