// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.side-row[data-v-472e5310] {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
}
.dimmed[data-v-472e5310] {
  color: #9c9ca0;
  min-width: 40px;
  width: 6ch;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/assignmentTable/OriginDestination.vue"],"names":[],"mappings":";AAgDA;EACE,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,eAAe;EACf,UAAU;AACZ","sourcesContent":["<template>\n  <div class=\"side-row\">\n    <TextData variant=\"xs\" class=\"dimmed\">{{ t('assignment.table.from') }}{{ CHAR.NNBSP }}: </TextData>\n    <n-tooltip :delay=\"TOOLTIP_DELAY_IN_MS\" trigger=\"hover\">\n      <template #trigger>\n        <TextData variant=\"xs\" hide-overflow>\n          {{ origin }}\n        </TextData>\n      </template>\n      {{ origin }}\n    </n-tooltip>\n  </div>\n  <div class=\"side-row\">\n    <TextData variant=\"xs\" class=\"dimmed\">{{ t('assignment.table.to') }}{{ CHAR.NNBSP }}: </TextData>\n    <n-tooltip :delay=\"TOOLTIP_DELAY_IN_MS\" trigger=\"hover\">\n      <template #trigger>\n        <TextData variant=\"xs\" hide-overflow>\n          {{ destination }}\n        </TextData>\n      </template>\n      {{ destination }}\n    </n-tooltip>\n  </div>\n</template>\n\n<script setup>\nimport { useI18n } from 'vue-i18n';\nimport { NTooltip } from 'naive-ui';\n\nimport { CHAR, TOOLTIP_DELAY_IN_MS } from '../../../constants';\n\nimport TextData from './TextData.vue';\n\nconst { t } = useI18n({ useScope: 'global' });\n\ndefineProps({\n  origin: {\n    type: String,\n    default: () => null,\n  },\n  destination: {\n    type: String,\n    default: () => null,\n  },\n});\n</script>\n\n<style scoped>\n.side-row {\n  display: flex;\n  justify-content: flex-start;\n  align-items: baseline;\n  flex-wrap: nowrap;\n  text-overflow: ellipsis;\n}\n.dimmed {\n  color: #9c9ca0;\n  min-width: 40px;\n  width: 6ch;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
