<template>
  <svg
    width="39"
    height="31"
    viewBox="-2.5 -5 37 38.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="paint-order: stroke"
    class="outline"
  >
    <path
      d="M36.5265 10.4688H33.6824L32.2199 6.8125C31 3.5 28.1302 0.625 24.0811 0.625H14.9189C10.8707 0.625 8 3.5 6.7792 6.8125L5.3167 10.4688H2.47266C1.78623 10.4688 1.28262 11.1139 1.44961 11.7792L1.97695 13.8886C2.09385 14.3579 2.51572 14.6875 3 14.6875H3.34688C2.1665 15.7185 1.40625 17.217 1.40625 18.9062V21.125C1.40625 22.5418 1.94766 23.8206 2.8125 24.8103V27.5625C2.8125 29.1155 4.07197 30.375 5.625 30.375H7.4375C8.99053 30.375 10.25 29.1155 10.25 27.5625V24.75H28.75V27.5625C28.75 29.1155 30.0095 30.375 31.5625 30.375H33.375C34.928 30.375 36.1875 29.1155 36.1875 27.5625V24.8103C37.0523 23.8215 37.5938 22.5427 37.5938 21.125V18.9062C37.5938 17.217 36.8335 15.7185 35.654 14.6875H36C36.4843 14.6875 36.9062 14.3579 37.0231 13.8886L37.5504 11.7792C37.7165 11.1139 37.2129 10.4688 36.5265 10.4688Z"
      :class="{ 'hide-secondary-outline': !showSecondaryOutline }"
      stroke="black"
      stroke-width="5.5"
    />
    <path
      d="M36.5265 10.4688H33.6824L32.2199 6.8125C31 3.5 28.1302 0.625 24.0811 0.625H14.9189C10.8707 0.625 8 3.5 6.7792 6.8125L5.3167 10.4688H2.47266C1.78623 10.4688 1.28262 11.1139 1.44961 11.7792L1.97695 13.8886C2.09385 14.3579 2.51572 14.6875 3 14.6875H3.34688C2.1665 15.7185 1.40625 17.217 1.40625 18.9062V21.125C1.40625 22.5418 1.94766 23.8206 2.8125 24.8103V27.5625C2.8125 29.1155 4.07197 30.375 5.625 30.375H7.4375C8.99053 30.375 10.25 29.1155 10.25 27.5625V24.75H28.75V27.5625C28.75 29.1155 30.0095 30.375 31.5625 30.375H33.375C34.928 30.375 36.1875 29.1155 36.1875 27.5625V24.8103C37.0523 23.8215 37.5938 22.5427 37.5938 21.125V18.9062C37.5938 17.217 36.8335 15.7185 35.654 14.6875H36C36.4843 14.6875 36.9062 14.3579 37.0231 13.8886L37.5504 11.7792C37.7165 11.1139 37.2129 10.4688 36.5265 10.4688Z"
      :fill="fillColor"
      :stroke="outlineColor"
      :stroke-width="outlineWidth"
    />

    <text class="centered" x="52%" y="40%" :fill="labelColor" dominant-baseline="middle" text-anchor="middle">{{ label }}</text>

    <path
      v-if="isElectric"
      :d="electricMarkerSpecs.background.path"
      :fill="electricMarkerSpecs.background.fillColor"
      :stroke="electricMarkerSpecs.background.strokeColor"
      :stroke-width="electricMarkerSpecs.background.strokeWeight * 2"
    />
    <path
      v-if="isElectric"
      :d="electricMarkerSpecs.foreground.path"
      :fill="electricMarkerSpecs.foreground.fillColor"
      :stroke-width="electricMarkerSpecs.foreground.strokeWeight"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue';
import useElectricMarkerSpecs from './map/useElectricMarkerSpecs';

const ELECTRIC_MARKER_OFFSET = { x: 5.5, y: 3 };

const props = defineProps({
  fillColor: {
    type: String,
    required: true,
  },
  label: {
    type: [String, Number],
    default: () => '',
  },
  labelColor: {
    type: String,
    default: 'black',
  },
  outlineColor: {
    type: String,
    default: 'black',
  },
  highlightOutline: {
    type: Boolean,
    default: false,
  },
  isElectric: {
    type: Boolean,
    default: false,
  },
});

const outlineWidth = computed(() => (props.highlightOutline ? 4.5 : 2));
const showSecondaryOutline = computed(() => props.highlightOutline);

const electricMarkerSpecs = useElectricMarkerSpecs(ELECTRIC_MARKER_OFFSET);
</script>

<style scoped>
.centered {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
}

.hide-stroke {
  paint-order: stroke;
}

.hide-secondary-outline {
  display: none;
}

.blue path {
  fill: #008cff;
}

.blue text {
  fill: #fff;
}

.green path {
  fill: #008000;
}

.green text {
  fill: #fff;
}

.gray path {
  fill: #808080;
}

.gray text {
  fill: #fff;
}

.orange path {
  fill: #ffa500;
}

.orange text {
  fill: #000;
}

.red path {
  fill: #f00;
}

.red text {
  fill: #fff;
}

.white path {
  fill: #fff;
}

.white text {
  fill: #000;
}
</style>
