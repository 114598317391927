<template>
  <component :is="tag" :class="classes">
    <template v-if="isEmpty">{{ CHAR.EM_DASH }}</template>
    <slot />
  </component>
</template>

<script>
import { computed, defineComponent, toRef } from 'vue';
import { CHAR } from '../../../constants';

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: () => 'md',
      validator(value) {
        return ['xl', 'lg', 'md', 'sm', 'xs', 'xxs'].indexOf(value) !== -1;
      },
    },
    weight: {
      type: String,
      default: () => 'normal',
      validator(value) {
        return ['normal', 'bold'].indexOf(value) !== -1;
      },
    },
    as: {
      type: String,
      default: () => 'p',
      validator(value) {
        return ['p', 'span', 'li'].indexOf(value) !== -1;
      },
    },
    hideOverflow: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: 'black',
    },
  },
  setup(props, context) {
    const tag = toRef(props, 'as');
    const slots = toRef(context, 'slots');

    const isEmpty = computed(() => {
      if (!slots.value.default) {
        return true;
      }
      const slot = slots.value.default();
      if (slot.length === 0) {
        return true;
      }
      const slotType = slot[0].type.toString();
      if (slotType === 'Symbol()') {
        return slot[0].children.length === 0;
      }
      if (slotType === 'Symbol(Text)') {
        return slot[0].children.length === 0;
      }
      if (slotType === 'Symbol(Fragment)') {
        const innerText = slot[0].children.reduce((cumul, item) => {
          if (item.type.toString() === 'Symbol(Text)') {
            return cumul.concat(item.children);
          }
          return cumul;
        }, '');
        return innerText.length === 0;
      }
      if (slotType === 'Symbol(Comment)') {
        if (slot[0].children.length === 0) {
          return true;
        }
        return slot[0].children === 'v-if';
      }
      return false;
    });
    const fontSize = computed(() => `text-data-${props.variant}`);
    const fontWeight = computed(() => `text-data-${props.weight}`);

    const classes = [
      'text-data-color',
      'text-data-component',
      fontSize.value,
      fontWeight.value,
      { 'text-data-dimmed': isEmpty.value },
    ];

    if (props.hideOverflow) {
      classes.push('hide-overflow-ellipsis');
    }

    return {
      fontSize,
      fontWeight,
      tag,
      isEmpty,
      CHAR,
      classes,
    };
  },
});
</script>

<style>
.hide-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-data-component {
  line-height: 1.2;
  margin: 0;
}

.text-data-xl {
  font-size: 22px;
}

.text-data-lg {
  font-size: 18px;
}

.text-data-md {
  font-size: 16px;
}

.text-data-sm {
  font-size: 14px;
}

.text-data-xs {
  font-size: 12px;
}

.text-data-xxs {
  font-size: 11px;
}

.text-data-normal {
  font-weight: 400;
}

.text-data-bold {
  font-weight: 600;
}

.text-data-dimmed {
  color: #b3b3b9;
}

.text-data-color {
  color: v-bind(fontColor);
}
</style>
