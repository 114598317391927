import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import TransportStatus from '../transport/TransportStatus';
var useTransportStatusFilter = function (initialStatuses) {
    var t = useI18n({ useScope: 'global' }).t;
    var hiddenStatuses = ref(initialStatuses);
    var options = [{ label: t('transport.status.ALLOCATED'), value: TransportStatus.ALLOCATED }, { label: t('transport.status.ON_BOARD'), value: TransportStatus.ON_BOARD }];
    var allocatedIsDisplayed = computed(function () { return !hiddenStatuses.value.includes(TransportStatus.ALLOCATED); });
    var onBoardIsDisplayed = computed(function () { return !hiddenStatuses.value.includes(TransportStatus.ON_BOARD); });
    return {
        hiddenStatuses: hiddenStatuses,
        options: options,
        allocatedIsDisplayed: allocatedIsDisplayed,
        onBoardIsDisplayed: onBoardIsDisplayed
    };
};
export default useTransportStatusFilter;
