// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.flex-wrapper[data-v-4356444e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-group[data-v-4356444e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(65% - 15px);
}
.title[data-v-4356444e] {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.date[data-v-4356444e] {
  color: #9c9ca0;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/AssignmentHeader.vue"],"names":[],"mappings":";AAkCA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;AACzB;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["<template>\n  <div class=\"flex-wrapper\">\n    <div class=\"header-group\">\n      <h3 class=\"title\">\n        {{ t('assignment.title') }}\n      </h3>\n\n      <PiloteProgressTag v-if=\"timer\" :percentage=\"timer.percentage.value\">\n        <div class=\"progress-tag-label\">{{ formatMilliseconds(timer.timeInMs.value) }}</div>\n      </PiloteProgressTag>\n    </div>\n\n    <div class=\"date\">{{ currentDate }}</div>\n  </div>\n</template>\n\n<script setup>\nimport { useI18n } from 'vue-i18n';\nimport { formatDate, formatMilliseconds } from '../../helpers/Formatters';\nimport PiloteProgressTag from '../generic/progress/PiloteProgressTag.vue';\n\ndefineProps({\n  timer: {\n    type: Object,\n    required: false,\n    default: null,\n  },\n});\n\nconst { t, locale } = useI18n({ useScope: 'global' });\nconst currentDate = formatDate(Date.now(), locale.value);\n</script>\n\n<style scoped>\n.flex-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.header-group {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: calc(65% - 15px);\n}\n\n.title {\n  font-size: 22px;\n  font-weight: 600;\n  margin: 0;\n}\n\n.date {\n  color: #9c9ca0;\n  font-size: 15px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
