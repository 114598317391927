var EXCLUSION_MARKER_FOREGROUND_FILL_COLOR = "#000";
var EXCLUSION_MARKER_FOREGROUND_FILL_OPACITY = 1;
var EXCLUSION_MARKER_FOREGROUND_STROKE_COLOR = "#000";
var EXCLUSION_MARKER_FOREGROUND_STROKE_WEIGHT = 1;
var EXCLUSION_MARKER_BACKGROUND_FILL_COLOR = "#fff";
var EXCLUSION_MARKER_BACKGROUND_FILL_OPACITY = 1;
var EXCLUSION_MARKER_BACKGROUND_STROKE_WEIGHT = 0;
var DELTA_BETWEEN_EXCLUSION_FOREGROUND_AND_BACKGROUND = { x: 7.65, y: 7.5 };
var exclusionMarkerForegroundPathWithOffset = function (offset) { return "\n  M ".concat(offset.x, " ").concat(offset.y, "\n  c -4.142 0 -7.5 3.358 -7.5 7.5\n  c 0 4.142 3.358 7.5 7.5 7.5\n  c 4.142 0 7.5 -3.358 7.5 -7.5\n  c 0 -4.142 -3.358 -7.5 -7.5 -7.5\n  z\n  m 3.935 3.565\n  c 1.979 1.979 2.117 5.005 0.625 7.126l-7.751 -7.751\n  c 2.123 -1.493 5.148 -1.353 7.126 0.625\n  z\n  m -7.87 7.869\n  c -1.979 -1.979 -2.116 -5.004 -0.625 -7.126l7.752 7.752\n  c -2.123 1.492 -5.148 1.353 -7.127 -0.626l0 0\n  z\n"); };
var exclusionMarkerBackgroundPathWithOffset = function (offset) {
    var cumulativeOffset = {
        x: offset.x + DELTA_BETWEEN_EXCLUSION_FOREGROUND_AND_BACKGROUND.x,
        y: offset.y + DELTA_BETWEEN_EXCLUSION_FOREGROUND_AND_BACKGROUND.y
    };
    return "\n    M ".concat(cumulativeOffset.x, " ").concat(cumulativeOffset.y, "\n    c 0 4.175 -3.385 7.56 -7.56 7.56\n    c -4.175 0 -7.56 -3.385 -7.56 -7.56\n    c 0 -4.175 3.385 -7.56 7.56 -7.56\n    c 4.175 0 7.56 3.385 7.56 7.56\n    z\n  ");
};
var useExclusionMarkerSpecs = function (offset) {
    return {
        background: {
            path: exclusionMarkerBackgroundPathWithOffset(offset),
            fillColor: EXCLUSION_MARKER_BACKGROUND_FILL_COLOR,
            fillOpacity: EXCLUSION_MARKER_BACKGROUND_FILL_OPACITY,
            strokeWeight: EXCLUSION_MARKER_BACKGROUND_STROKE_WEIGHT,
        },
        foreground: {
            path: exclusionMarkerForegroundPathWithOffset(offset),
            fillColor: EXCLUSION_MARKER_FOREGROUND_FILL_COLOR,
            fillOpacity: EXCLUSION_MARKER_FOREGROUND_FILL_OPACITY,
            strokeWeight: EXCLUSION_MARKER_FOREGROUND_STROKE_WEIGHT,
            strokeColor: EXCLUSION_MARKER_FOREGROUND_STROKE_COLOR,
        },
    };
};
export default useExclusionMarkerSpecs;
