<template>
  <svg :width="width" :height="height" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.3984 27.2772C31.8494 24.528 34.0278 20.4949 34.0278 16C34.0278 7.71573 26.628 1 17.5 1C8.37197 1 0.972229 7.71573 0.972229 16C0.972229 20.4866 3.14265 24.5132 6.58258 27.262L17.5 37L28.3984 27.2772Z"
      :fill="markerSpecs.fill.color"
      :stroke="markerSpecs.outline.color"
      :stroke-width="markerSpecs.outline.weight"
    />
    <text
      x="50%"
      y="45%"
      dominant-baseline="middle"
      text-anchor="middle"
      :fill="markerSpecs.label.color"
      :font-family="markerSpecs.label.fontFamily"
      :font-size="markerSpecs.label.fontSize"
      :font-weight="markerSpecs.label.fontWeight"
    >
      {{ num }}
    </text>
  </svg>
</template>

<script setup>
import { computed } from 'vue';

import useTransportMarkerSpecs from './useTransportMarkerSpecs';
import TransportRequestStatus from '../../transport/TransportStatus';

const props = defineProps({
  num: {
    type: String,
    default: () => 'A',
  },
  status: {
    type: String,
    default: () => TransportRequestStatus.PENDING,
  },
  height: {
    type: Number,
    default: 30,
  },
  width: {
    type: Number,
    default: 38,
  },
});

const markerSpecs = computed(() => {
  const transport = { status: props.status, number: props.num };
  return useTransportMarkerSpecs(transport);
});
</script>
