// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-row[data-v-50019eb6] {
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #e2e1e5;
  padding-left: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/assignmentTable/rowTypes/HeaderTableRow.vue"],"names":[],"mappings":";AAyDA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gCAAgC;EAChC,iBAAiB;AACnB","sourcesContent":["<template>\n  <div class=\"header-row\">\n    <div class=\"assignment-row-transport-marker\">\n      <AssignmentTableHeaderCell :title=\"firstHeaderLabel\" style=\"padding-left: 13px\" />\n    </div>\n\n    <div class=\"assignment-row-wait-time\">\n      <AssignmentTableHeaderCell :title=\"secondHeaderLabel\" />\n    </div>\n\n    <div class=\"assignment-row-route-information\">\n      <AssignmentTableHeaderCell :title=\"thirdHeaderLabel\" />\n    </div>\n\n    <div v-if=\"showPassengersColumn\" class=\"assignment-row-number-passengers\">\n      <AssignmentTableHeaderCell :title=\"sixthHeaderLabel\" />\n    </div>\n\n    <div v-if=\"showTeamColumn\" class=\"assignment-row-team-marker\">\n      <AssignmentTableHeaderCell :title=\"fifthHeaderLabel\" />\n    </div>\n\n    <div class=\"assignment-row-origin-destination-header\">\n      <AssignmentTableHeaderCell :title=\"fourthHeaderLabel\" />\n    </div>\n  </div>\n</template>\n\n<script setup>\nimport AssignmentTableHeaderCell from '../AssignmentTableHeaderCell';\n\nconst props = defineProps({\n  headerLabels: {\n    type: Array,\n    required: true,\n  },\n  showTeamColumn: {\n    type: Boolean,\n    default: true,\n  },\n  showPassengersColumn: {\n    type: Boolean,\n    default: false,\n  },\n});\n\nconst [\n  firstHeaderLabel = '',\n  secondHeaderLabel = '',\n  thirdHeaderLabel = '',\n  fourthHeaderLabel = '',\n  fifthHeaderLabel = '',\n  sixthHeaderLabel = ''\n] = props.headerLabels;\n</script>\n\n<style scoped>\n.header-row {\n  display: flex;\n  align-items: center;\n  height: 45px;\n  border-bottom: 1px solid #e2e1e5;\n  padding-left: 2px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
