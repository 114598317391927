<template>
  <div class="pad-wrapper">
    <div class="nez-rouge section row-fluid">
      <h3 class="title">
        {{ t('map.title') }}
      </h3>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    return {
      t,
    };
  },
};
</script>

<style scoped>
.pad-wrapper {
  padding: 0 25px;
  margin-top: 3rem;
}
.title {
  padding-bottom: 10px;
}
</style>
