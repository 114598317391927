/* istanbul ignore file */

import axios from 'axios';
import querystring from 'querystring';

import { AXIOS_CONFIG, HTTP_STATUS_CODE } from '../../constants';
import {
  FILTER_TRANSPORT_STATUS_ALL_OPEN,
  TRANSPORT_BASE_NUMBERS_KEY,
  TRANSPORT_STATUS_KEY,
} from '../collections/transportQueryFields';
import TransportStatus from '../../components/transport/TransportStatus';
import { reassignResponseDtoToPartialTransportObject } from '../../helpers/Converters';

const TRANSPORT_PATH = '/app/api/transport';

const initialFilterState = {
  [TRANSPORT_BASE_NUMBERS_KEY]: [],
  [TRANSPORT_STATUS_KEY]: FILTER_TRANSPORT_STATUS_ALL_OPEN,
};

const whenNotEmpty = (data) => (data.empty ? [] : data);
const pendingTransports = (transports) => transports.filter((transport) => transport.status === TransportStatus.PENDING);
const assignedTransports = (transports) => transports.filter((transport) => transport.status === TransportStatus.ASSIGNED);
const allocatedTransports = (transports) => transports.filter((transport) => transport.status === TransportStatus.ALLOCATED);
const onBoardTransports = (transports) => transports.filter((transport) => transport.status === TransportStatus.ON_BOARD);

const sortByDescendingWaitingTime = (transports) =>
  transports.slice().sort((previousTransport, nextTransport) => previousTransport.createdOn - nextTransport.createdOn);

const waitingForRideTransports = (stateData) => [
  ...pendingTransports(stateData),
  ...assignedTransports(stateData),
  ...allocatedTransports(stateData),
];

const findTransportById = (state, transportId) => {
  const transport = state.data.find((t) => t.transportRequestId === transportId);
  if (!transport) {
    throw new Error(`Transport with Id ${transportId} was not found.`);
  }
  return transport;
};

const getAssistedAssignmentToggleRequestPath = (transport) => {
  const requestPathSuffix = transport.isExcludedFromAssistedAssignment ? 'include' : 'exclude';
  return `${TRANSPORT_PATH}/${transport.transportRequestId}/assisted_assignment/${requestPathSuffix}`;
};

const setIsExcludedFromAssistedAssignmentForTransport = async (
  { commit, state },
  { transportId, isExcludedFromAssistedAssignment, onError = () => {} },
) => {
  try {
    const existingTransport = findTransportById(state, transportId);

    const requestPath = getAssistedAssignmentToggleRequestPath(existingTransport);
    const response = await axios.post(requestPath, {}, AXIOS_CONFIG);

    if (response.status === 204) {
      const updatedTransport = { ...existingTransport, isExcludedFromAssistedAssignment };
      const otherTransports = state.data.filter(
        (transport) => transport.transportRequestId !== updatedTransport.transportRequestId,
      );
      commit('setTransports', [updatedTransport, ...otherTransports]);
    }
    if (response.status === 400) {
      onError();
    }
  } catch (error) {
    commit('setError', error);
  }
};

export default {
  namespaced: true,
  state: {
    data: [],
    filter: { ...initialFilterState },
    error: undefined,
  },
  mutations: {
    resetFilter(state) {
      state.filter = initialFilterState;
    },
    setTransportStatus(state, transport) {
      const transportToUpdate = state.data.find((t) => t.transportRequestId === transport.transportRequestId);

      if (transportToUpdate) {
        transportToUpdate.status = transport.status;
      }
    },
    setTransportTeamId(state, { transportRequestId, teamId }) {
      const transportToUpdate = state.data.find((t) => t.transportRequestId === transportRequestId);

      if (transportToUpdate) {
        transportToUpdate.teamId = teamId;
      }
    },
    setTransports(state, transports) {
      state.data = transports;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async allocate({ commit }, transportId) {
      try {
        commit('setError', undefined);
        const response = await axios.post(`api/transport/${transportId}/allocate`);
        if (response.status < HTTP_STATUS_CODE.BAD_REQUEST) {
          commit('setTransportStatus', response.data);
        }
      } catch (error) {
        commit('setError', error);
      }
    },
    async reassign({ commit }, transportId) {
      try {
        commit('setError', undefined);
        const response = await axios.post(`api/transport/${transportId}/auto_assignment/reassign`);
        if (response.status < HTTP_STATUS_CODE.BAD_REQUEST) {
          const teamUpdateInformation = reassignResponseDtoToPartialTransportObject(response.data);
          commit('setTransportStatus', teamUpdateInformation);
          commit('setTransportTeamId', { transportRequestId: teamUpdateInformation.transportRequestId, teamId: null });
          commit('teams/setTeamStatus', response.data.team, { root: true });
        }
      } catch (error) {
        commit('setError', error);
      }
    },
    async fetch({ commit, state }) {
      try {
        commit('setError', undefined);
        const response = await axios.post('/app/api/transport', querystring.stringify(state.filter), AXIOS_CONFIG);
        if (response.status < HTTP_STATUS_CODE.BAD_REQUEST) {
          commit('setTransports', response.data);
        }
      } catch (error) {
        commit('setError', error);
      }
    },
    resetFilter({ commit }) {
      commit('resetFilter');
    },
    async submitFilter({ dispatch, commit }, filter) {
      commit('setFilter', filter);

      await dispatch('fetch');
    },
    async excludeTransportFromAssistedAssignment(store, transportId) {
      const options = { transportId, isExcludedFromAssistedAssignment: true };
      await setIsExcludedFromAssistedAssignmentForTransport(store, options);
    },
    async includeTransportInAssistedAssignment(store, { transportId, onError }) {
      const options = { transportId, isExcludedFromAssistedAssignment: false, onError };
      await setIsExcludedFromAssistedAssignmentForTransport(store, options);
    },
  },
  getters: {
    allocatedByDescendingWaitingTime: (state) => sortByDescendingWaitingTime(allocatedTransports(whenNotEmpty(state.data))),
    assignedByDescendingWaitingTime: (state) => sortByDescendingWaitingTime(assignedTransports(whenNotEmpty(state.data))),
    getTransportByTeamId: (state) => (teamId) => state.data.find((transport) => !!teamId && transport.teamId === teamId) || null,
    onBoardByDescendingWaitingTime: (state) => sortByDescendingWaitingTime(onBoardTransports(whenNotEmpty(state.data))),
    pendingByDescendingWaitingTime: (state) => sortByDescendingWaitingTime(pendingTransports(whenNotEmpty(state.data))),
    waitingForRide: (state) => waitingForRideTransports(whenNotEmpty(state.data)),
  },
};
