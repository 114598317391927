/* istanbul ignore file */
var module = {
    namespaced: true,
    state: {
        activeInfoWindowId: null,
        focusedTransportId: null,
    },
    mutations: {
        setActiveInfoWindowId: function (state, activeInfoWindowId) {
            state.activeInfoWindowId = activeInfoWindowId;
        },
        setFocusedTransportId: function (state, focusedTransportId) {
            state.focusedTransportId = focusedTransportId;
        },
    },
    actions: {
        toggleFocusedTransportId: function (_a, focusedTransportId) {
            var dispatch = _a.dispatch, state = _a.state;
            var isAlreadyFocused = state.focusedTransportId === focusedTransportId;
            isAlreadyFocused ?
                dispatch('unfocusTransport') :
                dispatch('focusTransport', focusedTransportId);
        },
        focusTransport: function (_a, focusedTransportId) {
            var commit = _a.commit, dispatch = _a.dispatch;
            commit('setActiveInfoWindowId', null);
            commit('map/setFitAndPanToBounds', true, { root: true });
            commit('setFocusedTransportId', focusedTransportId);
        },
        unfocusTransport: function (_a) {
            var commit = _a.commit;
            commit('setActiveInfoWindowId', null);
            commit('map/setFitAndPanToBounds', true, { root: true });
            commit('setFocusedTransportId', null);
        },
    },
};
export default module;
