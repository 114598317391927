/* istanbul ignore file */

import { create } from 'naive-ui';

export const createNaiveUI = () => create();

const internalSelectMenuHeight = 'clamp(3rem, calc(var(--option-height) * 15.66), 90vh)';

export const themeConfigAddendum = {
  Select: {
    peers: {
      InternalSelectMenu: {
        height: internalSelectMenuHeight,
      },
    },
  },
  AutoComplete: {
    peers: {
      InternalSelectMenu: {
        height: internalSelectMenuHeight,
      },
    },
  },
};
