<template>
  <div class="info-window">
    <slot name="header"></slot>

    <div class="separator"></div>

    <slot name="content"></slot>

    <div class="info-window-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped>
.separator {
  height: 1px;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: lightgray;
}
</style>

<style>
.info-window {
  width: 100%;
}

.info-window-header {
  display: flex;
  align-items: center;
}

.info-window-title-wrapper {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-window-hyperlink {
  text-decoration: underline;
}

.info-window-hyperlink:focus {
  outline: none;
}

.info-window-hyperlink:hover {
  font-weight: bold;
}

.info-window-row {
  margin-left: 5px;
  margin-bottom: 5px;
}

.info-window-footer {
  margin-top: 10px;
}
</style>
