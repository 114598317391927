import { TEAM_LABEL_OFFSET, TEAM_EXCLUSION_MARKER_OFFSET, TEAM_ELECTRIC_MARKER_OFFSET, } from '../team/teamMarkerConfig';
import useTeamMarkerSpecs from '../team/useTeamMarkerSpecs';
import { TRANSPORT_DESTINATION_LABEL_OFFSET, TRANSPORT_ORIGIN_LABEL_OFFSET, TRANSPORT_ORIGIN_EXCLUSION_MARKER_OFFSET, TRANSPORT_DESTINATION_EXCLUSION_MARKER_OFFSET, } from '../transport/transportMarkerConfig';
import useTransportMarkerSpecs from '../transport/useTransportMarkerSpecs';
import useElectricMarkerSpecs from './useElectricMarkerSpecs';
import useExclusionMarkerSpecs from './useExclusionMarkerSpecs';
var QuebecCityCoordinates = { lat: 46.81771, lng: -71.229457 };
export var DEFAULT_MAP_CENTER = QuebecCityCoordinates;
export var DEFAULT_MAP_ZOOM = 11;
export var DEFAULT_MAP_OPTIONS = {
    disableDefaultUI: true,
    zoomControl: true,
    controlSize: 28,
    maxZoom: 18,
    gestureHandling: 'greedy',
};
export var getTeamMarkerIcon = function (team) {
    var isFocused = false;
    var options = { isFocused: isFocused };
    var _a = useTeamMarkerSpecs(team, options), path = _a.path, scale = _a.scale, fill = _a.fill, outline = _a.outline;
    return {
        path: path,
        fillColor: fill.color,
        fillOpacity: fill.opacity,
        strokeWeight: outline.weight,
        strokeColor: outline.color,
        scale: scale,
        labelOrigin: TEAM_LABEL_OFFSET,
    };
};
export var getTeamMarkerLabel = function (team) {
    var label = useTeamMarkerSpecs(team).label;
    return {
        text: label.text,
        color: label.color,
        fontFamily: label.fontFamily,
        fontSize: label.fontSize,
        fontWeight: label.fontWeight,
    };
};
var getTransportMarkerLabelOriginOffset = function (isOrigin) {
    return isOrigin ? TRANSPORT_ORIGIN_LABEL_OFFSET : TRANSPORT_DESTINATION_LABEL_OFFSET;
};
var getTransportMarkerIcon = function (transport, isOrigin) {
    var isFocused = false;
    var options = { isOrigin: isOrigin, isFocused: isFocused };
    var _a = useTransportMarkerSpecs(transport, options), path = _a.path, scale = _a.scale, fill = _a.fill, outline = _a.outline;
    return {
        path: path,
        fillColor: fill.color,
        fillOpacity: fill.opacity,
        strokeWeight: outline.weight,
        strokeColor: outline.color,
        scale: scale,
        labelOrigin: getTransportMarkerLabelOriginOffset(isOrigin),
    };
};
export var getTransportOriginMarkerIcon = function (transport) {
    var isOrigin = true;
    return getTransportMarkerIcon(transport, isOrigin);
};
export var getTransportDestinationMarkerIcon = function (transport) {
    var isOrigin = false;
    return getTransportMarkerIcon(transport, isOrigin);
};
export var getTransportMarkerLabel = function (transport) {
    var label = useTransportMarkerSpecs(transport).label;
    return {
        text: label.text,
        color: label.color,
        fontFamily: label.fontFamily,
        fontSize: label.fontSize,
        fontWeight: label.fontWeight,
    };
};
export var getTeamMarkerId = function (team) { return "team-".concat(team.id); };
export var getTransportMarkerId = function (transport, isOrigin) {
    return isOrigin ?
        "transport-".concat(transport.transportRequestId) :
        "transport-destination-".concat(transport.transportRequestId);
};
export var getTeamExclusionMarkerIcon = function () {
    return useExclusionMarkerSpecs(TEAM_EXCLUSION_MARKER_OFFSET);
};
export var getTransportExclusionMarkerIcon = function (isOrigin) {
    var markerOffset = isOrigin ?
        TRANSPORT_ORIGIN_EXCLUSION_MARKER_OFFSET :
        TRANSPORT_DESTINATION_EXCLUSION_MARKER_OFFSET;
    return useExclusionMarkerSpecs(markerOffset);
};
export var getTeamElectricMarkerIcon = function () {
    return useElectricMarkerSpecs(TEAM_ELECTRIC_MARKER_OFFSET);
};
