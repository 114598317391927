<template>
  <div class="weight-normal" style="min-width: 32ch">
    <div class="address-group">
      <div class="title">{{ $t('transport.origin') }}</div>
      <div class="address">
        <div v-if="institutionName" class="address-line full-width">{{ institutionName }}</div>
        <div class="address-line full-width">{{ originAddress }}</div>
      </div>
    </div>

    <div class="address-group">
      <div class="title">{{ $t('transport.destination') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ destinationAddress }}</div>
      </div>
    </div>

    <div v-if="fullName" class="address-group">
      <div class="title">{{ $t('transport.requester') }}</div>
      <div class="address">
        <div class="address-line full-width">{{ fullName }}</div>
      </div>
    </div>

    <div v-if="vehicleInfo" class="address-group">
      <span class="title">{{ $t('transport.vehicle') }}</span>
      <div>
        <div class="address">
          <div class="address-line full-width">{{ vehicleInfo }}</div>
        </div>
      </div>
    </div>

    <div class="address-group view-link">
      <a :href="`transport/${transport.transportRequestId}`" target="_blank" class="no-outline">
        <span>{{ $t('transport.page') }}{{ transportRequestId }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { formatName, formatStandardAddress } from '../../helpers/Formatters';

export default defineComponent({
  props: {
    transport: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const institutionName = computed(() => props.transport.institutionName);
    const originAddress = computed(() =>
      formatStandardAddress(props.transport.street, props.transport.city, props.transport.province),
    );
    const destinationAddress = computed(() =>
      formatStandardAddress(
        props.transport.destinationStreet,
        props.transport.destinationCity,
        props.transport.destinationProvince,
      ),
    );
    const transportRequestId = computed(() => props.transport.id ?? props.transport.transportRequestId);
    const fullName = computed(() => formatName(props.transport.firstName, props.transport.lastName));
    const vehicleInfo = computed(() =>
      [props.transport.vehicleManufacturer, props.transport.vehicleModel].filter((item) => item).join(' – '),
    );

    return {
      institutionName,
      originAddress,
      destinationAddress,
      transportRequestId,
      fullName,
      vehicleInfo,
    };
  },
});
</script>

<style scoped>
.no-outline:focus {
  outline: none;
}

.address-group {
  margin-bottom: 0.5rem;
}

.weight-normal {
  font-weight: 400;
}

.title {
  font-weight: 500;
}
</style>
