import TeamType from '../components/team/TeamType';
import { teamStatusToIconName, vipTeamStatusToIconName } from './Converters';

const TEAM_MARKER_V2_ICON_PATH = '/img/map/2.0';
const TEAM_MARKER_V2_FILE_EXTENSION = 'svg';

export const buildTeamMarkerIconURI = (teamType, teamStatus) => {
  const teamIconName = teamType === TeamType.VIP ? vipTeamStatusToIconName(teamStatus) : teamStatusToIconName(teamStatus);
  return `${TEAM_MARKER_V2_ICON_PATH}/${teamIconName}.${TEAM_MARKER_V2_FILE_EXTENSION}`;
};
