import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-29c058c4"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "bottom-row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_GMapRectangle = _resolveComponent("GMapRectangle");
    var _component_GMapMarker = _resolveComponent("GMapMarker");
    var _component_GMapMap = _resolveComponent("GMapMap");
    var _component_n_checkbox = _resolveComponent("n-checkbox");
    var _component_TeamMarker = _resolveComponent("TeamMarker");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_GMapMap, {
            ref: "mapRef",
            class: _normalizeClass(['map-container', _ctx.extraClass]),
            center: _ctx.center,
            zoom: 11,
            options: {
                disableDefaultUI: true,
                zoomControl: true,
                controlSize: 28,
                maxZoom: 18,
                gestureHandling: 'greedy',
            }
        }, {
            default: _withCtx(function () { return [
                (_ctx.showMarkerBounds)
                    ? (_openBlock(), _createBlock(_component_GMapRectangle, {
                        key: 0,
                        bounds: _ctx.allMarkerBounds
                    }, null, 8 /* PROPS */, ["bounds"]))
                    : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positionedTeams, function (teamSnapshot, index) {
                    return (_openBlock(), _createBlock(_component_GMapMarker, {
                        key: _ctx.getTeamKey(teamSnapshot),
                        position: teamSnapshot.position,
                        icon: _ctx.getTeamMarkerIcon(teamSnapshot),
                        label: _ctx.getTeamMarkerLabel(teamSnapshot),
                        clickable: true,
                        draggable: false,
                        "z-index": teamSnapshot.isActive ? index + 3000 : index + 1000,
                        onClick: function ($event) { return (_ctx.inspectTeam(teamSnapshot)); }
                    }, null, 8 /* PROPS */, ["position", "icon", "label", "z-index", "onClick"]));
                }), 128 /* KEYED_FRAGMENT */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transportRequestsForOriginMarkers, function (transportSnapshot, index) {
                    return (_openBlock(), _createBlock(_component_GMapMarker, {
                        key: _ctx.getTransportKey(transportSnapshot),
                        position: transportSnapshot.origin.coordinates,
                        icon: _ctx.getTransportMarkerIcon(transportSnapshot),
                        label: _ctx.getTransportMarkerLabel(transportSnapshot),
                        clickable: true,
                        draggable: false,
                        "z-index": transportSnapshot.isActive ? index + 4000 : index + 2000,
                        onClick: function ($event) { return (_ctx.inspectTransport(transportSnapshot)); }
                    }, null, 8 /* PROPS */, ["position", "icon", "label", "z-index", "onClick"]));
                }), 128 /* KEYED_FRAGMENT */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transportRequestsForDestinationMarkers, function (transportSnapshot, index) {
                    return (_openBlock(), _createBlock(_component_GMapMarker, {
                        key: _ctx.getTransportKey(transportSnapshot),
                        position: transportSnapshot.destination.coordinates,
                        icon: _ctx.getTransportDestinationMarkerIcon(transportSnapshot),
                        label: _ctx.getTransportMarkerLabel(transportSnapshot),
                        clickable: true,
                        draggable: false,
                        "z-index": transportSnapshot.isActive ? index + 4000 : index + 2000,
                        onClick: function ($event) { return (_ctx.inspectTransport(transportSnapshot)); }
                    }, null, 8 /* PROPS */, ["position", "icon", "label", "z-index", "onClick"]));
                }), 128 /* KEYED_FRAGMENT */))
            ]; }),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "center"]),
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_n_checkbox, {
                checked: _ctx.fitAndPanToBounds,
                "onUpdate:checked": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.fitAndPanToBounds) = $event); }),
                size: "small"
            }, {
                default: _withCtx(function () { return [
                    _createTextVNode(_toDisplayString(_ctx.$t('map.autoZoom')), 1 /* TEXT */)
                ]; }),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["checked"]),
            _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unPositionedTeams, function (teamSnapshot) {
                    return (_openBlock(), _createBlock(_component_TeamMarker, {
                        key: teamSnapshot.id,
                        team: teamSnapshot,
                        class: "scaled-down-pointer",
                        onClick: function ($event) { return (_ctx.inspectTeam(teamSnapshot)); }
                    }, null, 8 /* PROPS */, ["team", "onClick"]));
                }), 128 /* KEYED_FRAGMENT */))
            ])
        ])
    ], 64 /* STABLE_FRAGMENT */));
}
