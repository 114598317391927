/* istanbul ignore file */

import axios from 'axios';

import { AXIOS_CONFIG } from '../../constants';

export default {
  namespaced: true,
  state: {
    data: [],
    error: undefined,
  },
  mutations: {
    setInstitutions(state, institutions) {
      state.data = institutions;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        commit('setError', undefined);
        const response = await axios.get('/app/api/institution', AXIOS_CONFIG);
        if (response.status < 400) {
          commit('setInstitutions', response.data);
        }
      } catch (err) {
        commit('setError', err);
      }
    },
  },
};
