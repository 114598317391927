/* istanbul ignore file */

import en from './locales/en';
import fr from './locales/fr';

export const i18nConfig = {
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
  },
};
