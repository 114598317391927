import { CHAR } from '../../constants';

export default {
  assignment: {
    awaitingTransportRequests: 'Awaiting',
    exclusion: {
      error: 'The request’s status has changed, please refresh this page',
      tooltip: 'This transport request is excluded from assisted assignment',
    },
    map: {
      removeSelection: 'Remove current selection',
      unpositionedTeams: {
        title: 'Vehicule(s) with unknown GPS location',
        hiddenTeam: 'other vehicule',
        hiddenTeams: 'other vehicules',
      },
    },
    table: {
      abbreviatedMinutes: 'min',
      anticipatedTeam: 'Anticipated Team',
      assignmentTableIsEmpty: 'No pending request',
      awaitingTime: 'Waiting',
      buttons: {
        allocate: {
          allocate: 'Dispatch',
        },
        reassign: {
          cancel: 'Cancel',
        },
      },
      dispatchTableIsEmpty: 'No request to dispatch',
      distanceDuration: 'Distance Duration',
      duration: 'Duration',
      filter: {
        placeholder: 'Hide requests with status',
      },
      from: 'From',
      originAndDestination: 'Origin Destination',
      modals: {
        reassign: {
          cancelAssignment: 'Cancel assignment',
          goBack: 'Go back',
          message:
            'Are you sure you want to cancel assignment of request {requestNumber} to team {teamNumber}? It is an irreversible action.',
          reassign: 'Reassign',
        },
      },
      notification: {
        assignmentConfirmation: {
          description: 'Transport request {formattedTransportId} (#{transportId}) was assigned to team {teamNumber}',
          title: 'Assignment confirmation',
        },
      },
      route: 'Route',
      team: 'Team',
      to: 'To',
      passengers_seatbelts: 'Passengers Seatbelts',
    },
    title: 'Computer assisted assignment',
    transportRequestsToDispatchAndOnARide: 'To dispatch and currently on a ride',
  },
  map: {
    placeholder: {
      baseNumber: 'Base number(s)',
      client: 'Client',
      evening: {
        number: 'Evening Number',
      },
      team: {
        number: 'Team Number',
      },
      transportRequestId: 'Transport request number',
    },
    select: {
      institution: 'Establishment',
      location: 'Starting location',
      teamStatus: 'Select a team status',
      transportStatus: 'Select a request status',
    },
    title: 'Map',
    autoZoom: 'Automatic zoom',
  },
  name: 'English',
  team: {
    base: 'Base #',
    byId: `${CHAR.NBSP}id=`,
    byNumber: `${CHAR.NBSP}#`,
    driver: 'Driver:',
    escort: 'Escort Driver:',
    infoWindow: {
      assignedTo: {
        label: 'Assigned to',
        none: 'None',
      },
      assistedAssignment: {
        exclude: 'Exclude from assisted assignment',
        include: 'Include in assisted assignment',
        exclusionModal: {
          closeLabel: 'Cancel',
          confirmLabel: 'Confirm',
          titlePrefix: 'Exclude team',
          titleSuffix: 'from assisted assignment?',
        },
      },
      motorType: {
        label: 'Motor type',
        gas: 'Gas',
        electric: 'Electric',
      },
      status: 'Status',
      team: 'Team',
      transmission: {
        label: 'Transmission',
        automatic: 'Automatic',
        manual: 'Manual',
      },
      unknown: 'N/A',
      vip: {
        label: 'VIP',
        yes: 'yes',
        no: 'no',
      },
      waitTime: 'Wait time',
    },
    members: 'Members',
    mobile: 'Mobile Phone:',
    page: `Go to${CHAR.NBSP}`,
    partner: 'Partner:',
    radio: 'Radio #:',
    standard: 'team',
    status: {
      NOT_DISSOLVED: 'All teams',
      ASSIGNED: 'Currently doing a ride',
      AVAILABLE_AT_COORDINATES: 'Available at coordinates',
      AVAILABLE_IN_INSTITUTION: 'Available in an establishment',
      AVAILABLE_IN_LOCATION: 'Available in a headquarters/satellite',
      AVAILABLE_IN_REGION: 'Available in a community',
      AVAILABLE: 'Available',
      DISSOLVED: 'Dissolved',
      IN_RETURN: 'In return',
      ON_BREAK: 'On break',
      tooltip: {
        NOT_DISSOLVED: 'All teams',
        ASSIGNED: 'Currently doing a ride',
        AVAILABLE_AT_COORDINATES: 'Available',
        AVAILABLE_IN_INSTITUTION: 'Available',
        AVAILABLE_IN_LOCATION: 'Available',
        AVAILABLE_IN_REGION: 'Available',
        AVAILABLE: 'Available',
        DISSOLVED: 'Dissolved',
        IN_RETURN: 'In return',
        ON_BREAK: 'On break',
      },
    },
    vip: 'V.I.P. team',
  },
  title: 'Computer Assisted Assignment Request',
  transport: {
    destination: 'Destination:',
    infoWindow: {
      assistedAssignment: {
        exclude: 'Exclude from assisted assignment',
        include: 'Include in assisted assignment',
        exclusionModal: {
          closeLabel: 'Cancel',
          confirmLabel: 'Confirm',
          titlePrefix: 'Exclude transport request',
          titleSuffix: 'from assisted assignment?',
        },
      },
      client: 'Client',
      destination: 'Destination',
      passengerCount: 'Passenger count',
      origin: 'Origin',
      smsSent: {
        label: 'Assignment SMS sent',
        no: 'No',
        yes: 'Yes',
      },
      team: 'Team',
      unassigned: 'None',
      unknown: 'N/A',
      vehicle: 'Vehicle',
      waitTime: 'Wait time',
    },
    origin: 'Origin:',
    page: 'Transport Request #',
    requester: 'Person requesting the ride:',
    status: {
      ALLOCATED: 'Allocated',
      ALL_OPEN_REQUESTS: 'All opened requests',
      ASSIGNED: 'Assigned',
      CANCELLED: 'Cancelled',
      COMPLETED: 'Completed',
      ON_BOARD: 'Client on board',
      PENDING: 'Pending',
      TRANSFERRED: 'Transferred',
      UNFINDABLE: 'Unfindable',
    },
    vehicle: 'Vehicule:',
  },
  originAndDestination: {
    placesToggle: 'Search via Google Maps',
    route: 'Route',
    origin: 'Origin',
    destination: 'Destination',
    institution: 'Establishment:',
    emptyMessage: 'Please enter at least one character.',
    placeholder: 'Residence or other establishment',
  },
  orientation: {
    EAST: 'East',
    NORTH: 'North',
    SOUTH: 'South',
    WEST: 'West',
  },
  place: {
    enterOrigin: 'Enter a starting point',
    enterDestination: 'Enter a destination',
  },
  vehicle: {
    color: {
      beige: 'Beige',
      black: 'Black',
      blue: 'Blue',
      brown: 'Brown',
      green: 'Green',
      grey: 'Grey',
      orange: 'Orange',
      other: 'Other',
      pink: 'Pink',
      purple: 'Purple',
      red: 'Red',
      silver: 'Silver',
      white: 'White',
      yellow: 'Yellow',
    },
  },
};
