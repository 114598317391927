import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "place-selector span12" };
var _hoisted_2 = {
    ref: "attrContainer",
    class: "autocomplete-attributions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_n_auto_complete = _resolveComponent("n-auto-complete");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_n_auto_complete, {
            class: "span12",
            value: _ctx.searchQuery,
            "onUpdate:value": [
                _cache[0] || (_cache[0] = function ($event) { return ((_ctx.searchQuery) = $event); }),
                _ctx.handleAutoComplete
            ],
            placeholder: _ctx.placeholder,
            options: _ctx.options,
            "input-props": { autocomplete: 'off' },
            "render-label": _ctx.renderLabel,
            onSelect: _cache[1] || (_cache[1] = function (placeId) { return _ctx.placeChanged(placeId.toString()); })
        }, null, 8 /* PROPS */, ["value", "placeholder", "options", "render-label", "onUpdate:value"]),
        _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
    ]));
}
