/* istanbul ignore file */

export const AXIOS_CONFIG = {
  validateStatus(status) {
    return status < 500;
  },
};

export const CHAR = {
  NBSP: ' ',
  NNBSP: ' ',
  ENSP: ' ',
  EN_DASH: '–',
  EM_DASH: '—',
};

export const HTTP_STATUS_CODE = { BAD_REQUEST: 400 };
export const SECONDS_PER_HOUR = 3600;
export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * 60;
export const TOOLTIP_DELAY_IN_MS = 400;
