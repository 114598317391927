<template>
  <div class="side-row">
    <TextData variant="xs" class="dimmed">{{ t('assignment.table.from') }}{{ CHAR.NNBSP }}: </TextData>
    <n-tooltip :delay="TOOLTIP_DELAY_IN_MS" trigger="hover">
      <template #trigger>
        <TextData variant="xs" hide-overflow>
          {{ origin }}
        </TextData>
      </template>
      {{ origin }}
    </n-tooltip>
  </div>
  <div class="side-row">
    <TextData variant="xs" class="dimmed">{{ t('assignment.table.to') }}{{ CHAR.NNBSP }}: </TextData>
    <n-tooltip :delay="TOOLTIP_DELAY_IN_MS" trigger="hover">
      <template #trigger>
        <TextData variant="xs" hide-overflow>
          {{ destination }}
        </TextData>
      </template>
      {{ destination }}
    </n-tooltip>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { NTooltip } from 'naive-ui';

import { CHAR, TOOLTIP_DELAY_IN_MS } from '../../../constants';

import TextData from './TextData.vue';

const { t } = useI18n({ useScope: 'global' });

defineProps({
  origin: {
    type: String,
    default: () => null,
  },
  destination: {
    type: String,
    default: () => null,
  },
});
</script>

<style scoped>
.side-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
}
.dimmed {
  color: #9c9ca0;
  min-width: 40px;
  width: 6ch;
}
</style>
