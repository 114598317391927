// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.transport-id-link {
  color: #003399;
  font-size: 11px;
  font-weight: 600;
  margin: 0 0 4px 0;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/assignmentTable/AssignmentTableTransportMarker.vue"],"names":[],"mappings":";AA2BA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":["<template>\n  <TransportMarker :num=\"num\" :status=\"status\" :width=\"26\" :height=\"30\" style=\"padding-top: 2px\" />\n\n  <a :href=\"`/app/transport/${transportId}`\">\n    <p class=\"transport-id-link\">#{{ transportId }}</p>\n  </a>\n</template>\n<script setup>\nimport TransportRequestStatus from '../../transport/TransportStatus';\nimport TransportMarker from '../../generic/transport/TransportMarker.vue';\n\ndefineProps({\n  num: {\n    type: String,\n    default: () => 'A',\n  },\n  status: {\n    type: String,\n    default: () => TransportRequestStatus.PENDING,\n  },\n  transportId: {\n    type: Number,\n    default: () => -1,\n  },\n});\n</script>\n<style>\n.transport-id-link {\n  color: #003399;\n  font-size: 11px;\n  font-weight: 600;\n  margin: 0 0 4px 0;\n  text-decoration: underline;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
