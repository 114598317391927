<template>
  <GMapMap
    ref="mapRef"
    :class="['map-container', extraClass]"
    :center="center"
    :zoom="11"
    :options="{
      disableDefaultUI: true,
      zoomControl: true,
      controlSize: 28,
      maxZoom: 18,
      gestureHandling: 'greedy',
    }"
  >
    <GMapMarker
      v-for="team in positionedTeams"
      :key="getTeamKey(team)"
      :position="team.position"
      :icon="getMarkerIcon(team)"
      :label="getMarkerLabel(team)"
      :clickable="true"
      :draggable="false"
      @click="toggleTeamInfoWindow(team)"
    >
      <GMapInfoWindow
        class="poi-info-window"
        :position="team.position"
        :opened="openedInfoWindowID === getTeamKey(team)"
        :closeclick="true"
        @closeclick="openedInfoWindowID === getTeamKey(team) ? (openedInfoWindowID = null) : undefined"
      >
        <TeamInfoWindow :team="team" />
      </GMapInfoWindow>
    </GMapMarker>

    <GMapMarker
      v-for="transport in transports"
      :key="getTransportKey(transport)"
      :position="transport.coordinates"
      :icon="{ url: '/img/map/purple-marker.png' }"
      :clickable="true"
      :draggable="false"
      @click="toggleTransportInfoWindow(transport)"
    >
      <GMapInfoWindow
        class="poi-info-window"
        :position="transport.coordinates"
        :opened="openedInfoWindowID === getTransportKey(transport)"
        :closeclick="true"
        @closeclick="openedInfoWindowID === getTransportKey(transport) ? (openedInfoWindowID = null) : undefined"
      >
        <TransportInfoWindow :transport="transport" />
      </GMapInfoWindow>
    </GMapMarker>
  </GMapMap>
  <div class="bottom-row">
    <n-checkbox v-model:checked="fitAndPanToBounds" size="small">{{ $t('map.autoZoom') }}</n-checkbox>
    <div>
      <TeamMarker v-for="team in unPositionedTeams" :key="getTeamKey(team)" :team="team" class="scaled-down-pointer" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { mapMutations, mapState } from 'vuex';
import { NCheckbox } from 'naive-ui';

import { buildTeamMarkerIconURI } from '../../helpers/URI';
import TeamMarker from '../generic/team/TeamMarker.vue';
import TeamInfoWindow from '../team/TeamInfoWindow.vue';
import TransportInfoWindow from '../transport/TransportInfoWindow.vue';

export default defineComponent({
  components: { TeamInfoWindow, TransportInfoWindow, NCheckbox, TeamMarker },
  props: {
    extraClass: {
      type: String,
      default: () => 'screen-h--22rem',
      validator(value) {
        return ['screen-h--22rem', 'full--1rem', 'none'].indexOf(value) !== -1;
      },
    },
  },
  emits: ['refresh-stores'],
  setup() {
    const mapRef = ref(null);
    const center = ref({ lat: 46.81771, lng: -71.229457 });
    const openedInfoWindowID = ref(null);

    const setCenter = ({ lat, lng }) => {
      center.value = { lat: lat ?? 46.81771, lng: lng ?? -71.229457 };
    };

    return {
      mapRef,
      center,
      openedInfoWindowID,
      setCenter,
    };
  },
  data() {
    return {
      googleApi: null,
      map: null,
      boundsListener: null,
      changingBounds: false,
    };
  },
  computed: {
    fitAndPanToBounds: {
      get() {
        return this.$store.state.map.fitAndPanToBounds;
      },
      set(value) {
        this.setFitAndPanToBounds(value);
        if (value) {
          this.$emit('refresh-stores');
        }
      },
    },
    ...mapState({
      positionedTeams: (state) => state.teams.data.filter((team) => team.position && team.position.lat),
      unPositionedTeams: (state) => state.teams.data.filter((team) => !team.position || !team.position.lat),
      transports: (state) => state.transports.data,
    }),
    allMarkerBounds() {
      if (this.googleApi && this.fitAndPanToBounds && (this.positionedTeams.length || this.transports.length)) {
        const bounds = new this.googleApi.maps.LatLngBounds();
        this.positionedTeams.forEach((team) => {
          if (team.position && team.position.lat) {
            const coordinates = new this.googleApi.maps.LatLng(team.position.lat, team.position.lng);
            bounds.extend(coordinates);
          }
        });
        this.transports.forEach((transportRequest) => {
          if (transportRequest.coordinates && transportRequest.coordinates.lat) {
            const coordinates = new this.googleApi.maps.LatLng(
              transportRequest.coordinates.lat,
              transportRequest.coordinates.lng,
            );
            bounds.extend(coordinates);
          }
        });
        this.setCenter(bounds.getCenter());
        return bounds;
      }
      return null;
    },
  },
  watch: {
    allMarkerBounds(newBounds) {
      if (!this.fitAndPanToBounds || !this.googleApi || !this.map) {
        return;
      }
      if (!newBounds) {
        return;
      }
      this.changingBounds = true;
      this.map.fitBounds(newBounds);
      this.map.panToBounds(newBounds);
      this.googleApi.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.changingBounds = false;
      });
    },
  },
  async mounted() {
    this.googleApi = await this.$refs.mapRef.$gmapApiPromiseLazy();
    this.map = await this.$refs.mapRef.$mapPromise;
    this.googleApi.maps.event.addListenerOnce(this.map, 'idle', () => this.attachListeners());
  },
  beforeUnmount() {
    this.detachListeners();
  },
  methods: {
    ...mapMutations({
      setFitAndPanToBounds: 'map/setFitAndPanToBounds',
    }),

    getMarkerIcon(team) {
      return { url: buildTeamMarkerIconURI(team.type, team.status) };
    },

    getMarkerLabel(team) {
      return {
        color: 'black',
        fontSize: '13px',
        fontWeight: '900',
        text: team.number ? team.number.toString() : ' ',
      };
    },

    attachListeners() {
      this.boundsListener = this.map.addListener('bounds_changed', () => {
        if (!this.changingBounds) {
          this.setFitAndPanToBounds(false);
        }
      });
    },
    detachListeners() {
      this.boundsListener.remove();
    },

    getTeamKey: (team) => 'team-'.concat(team.id),
    getTransportKey: (transport) => 'transport-'.concat(transport.transportRequestId),

    toggleTeamInfoWindow(team) {
      const id = this.getTeamKey(team);
      this.toggleInfoWindow(id);
    },
    toggleTransportInfoWindow(transport) {
      const id = this.getTransportKey(transport);
      this.toggleInfoWindow(id);
    },
    toggleInfoWindow(id) {
      if (this.openedInfoWindowID === id) {
        this.openedInfoWindowID = null;
      } else {
        this.openedInfoWindowID = id;
      }
    },
  },
});
</script>

<style scoped>
.map-container {
  width: 100%;
  min-height: 400px;
}

.screen-h--22rem {
  height: calc(100vh - 22rem);
  min-height: min-content;
}

.full--1rem {
  height: calc(100% - 1rem);
  min-height: min-content;
}

.none {
  height: clamp(200px, 40vh, 800px);
  min-height: 200px;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3px;
}

.scaled-down-pointer {
  zoom: 0.71;
  margin-right: 3px;
}
</style>
