export var TRANSPORT_ORIGIN_ICON_PATH = "\n  M 12.8984 -12.7228\n  c 3.451 -2.7492 5.6294 -6.7823 5.6294 -11.2772\n  c 0 -8.28427 -7.3998 -15 -16.5278 -15\n  c -9.12803 0 -16.527771 6.71573 -16.527771 15\n  c 0 4.4866 2.170421 8.5132 5.610351 11.262\n  l 10.91742 9.738\n  l 10.8984 -9.7228\n  z\n";
export var TRANSPORT_DESTINATION_ICON_PATH = "\n  M 4.00063 -27.99215l-0.394 1.028l1.034 -0.38\n  c 1.357 -0.499 2.819 -0.786 4.456 -0.786\n  c 2.204 0 4.036 0.567 5.814 1.157\n  c 0.107 0.036 0.215 0.072 0.322 0.108\n  c 1.65 0.55 3.3 1.101 5.103 1.101\n  c 2.87 0 5.818 -1.123 8.167 -2.214l-0.183 -0.394l0.183 0.394\n  c 1.112 -0.516 2.383 0.295 2.383 1.521\n  v 16.537\n  c 0 0.553 -0.271 1.07 -0.724 1.381l0.282 0.412l-0.282 -0.412\n  c -2.38 1.631 -4.902 2.692 -8.051 2.692\n  c -2.205 0 -4.037 -0.568 -5.814 -1.158\n  c -0.107 -0.036 -0.215 -0.071 -0.322 -0.107\n  c -1.65 -0.551 -3.301 -1.101 -5.104 -1.101\n  c -3.533 0 -5.999 0.7 -7.98 1.544l-0.304 0.13\n  v 0.33\n  v 6.422\n  c 0 0.626 -0.507 1.133 -1.133 1.133\n  h -1.088\n  c -0.626 0 -1.133 -0.507 -1.133 -1.133\n  v -26.262\n  v -0.261l-0.214 -0.149\n  c -0.858 -0.599 -1.419 -1.592 -1.419 -2.716\n  c 0 -1.873 1.557 -3.383 3.449 -3.306l0 0\n  c 1.68 0.068 3.059 1.417 3.164 3.096l0 0.001\n  c 0.03 0.474 -0.042 0.949 -0.212 1.392\n  z\n";
export var TRANSPORT_DEFAULT_ICON_SCALE = 1;
export var TRANSPORT_FOCUSED_ICON_SCALE = 1.5;
export var TRANSPORT_ICON_FILL_OPACITY = 1;
export var TRANSPORT_FILL_COLOR = {
    BLUEBERRY_BLUE: '#00ABE1',
    BASHFUL_PINK: '#BC478D',
    GREY: '#909090',
    AO_GREEN: '#008000',
    PRUSSIAN_BLUE: '#05375b',
    PERSIAN_RED: '#d03033',
    GRANITE_GRAY: '#606060',
};
export var TRANSPORT_DEFAULT_OUTLINE_WEIGHT = 1;
export var TRANSPORT_FOCUSED_OUTLINE_WEIGHT = 3;
export var TRANSPORT_OUTLINE_COLOR = {
    BLACK: '#000',
    WHITE: '#fff',
};
export var TRANSPORT_ORIGIN_LABEL_OFFSET = { x: 2, y: -24 };
export var TRANSPORT_DESTINATION_LABEL_OFFSET = { x: 16, y: -17.8 };
export var TRANSPORT_LABEL_COLOR = {
    BLACK: '#000',
    WHITE: '#fff',
};
export var TRANSPORT_LABEL_FONT_FAMILY = "'Open Sans', sans-serif";
export var TRANSPORT_LABEL_FONT_SIZE = '16px';
export var TRANSPORT_LABEL_FONT_WEIGHT = '600';
export var defaultMarkerOptions = {
    isOrigin: true,
    isFocused: false,
};
export var TRANSPORT_ORIGIN_EXCLUSION_MARKER_OFFSET = { x: 20, y: -42 };
export var TRANSPORT_DESTINATION_EXCLUSION_MARKER_OFFSET = { x: 35, y: -36 };
