var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { NCheckbox } from 'naive-ui';
import TeamMarker from '../generic/team/TeamMarker.vue';
import { key } from '../../store';
import TransportRequestStatus from '../transport/TransportStatus';
import { useTeamSnapshotMarkerSpecs } from '../generic/team/useTeamMarkerSpecs';
import { useTransportSnapshotMarkerSpecs } from '../generic/transport/useTransportMarkerSpecs';
export default defineComponent({
    components: { TeamMarker: TeamMarker, NCheckbox: NCheckbox },
    props: {
        positionedTeams: {
            type: Array,
            required: true,
        },
        unPositionedTeams: {
            type: Array,
            required: true,
        },
        transports: {
            type: Array,
            required: true,
        },
        extraClass: {
            type: String,
            default: function () { return 'screen-h--22rem'; },
            validator: function (value) { return ['screen-h--22rem', 'full--1rem', 'none'].indexOf(value) !== -1; },
        },
        showMarkerBounds: {
            type: Boolean,
            required: false,
            default: function () { return false; },
        },
    },
    emits: ['team:inspect', 'transport:inspect'],
    setup: function (props) {
        var _this = this;
        var store = useStore(key);
        var googleApi = ref();
        var map = ref();
        var changingBounds = ref(false);
        var mapRef = ref();
        var center = ref({ lat: 46.81771, lng: -71.229457 });
        var transportRequestsForOriginMarkers = computed(function () {
            var activeTransportSnapshotId = store.state.snapshots.activeTransportSnapshotId;
            return props.transports.filter(function (snapshot) {
                return snapshot.hasOriginCoordinates &&
                    (snapshot.status !== TransportRequestStatus.ON_BOARD ||
                        (activeTransportSnapshotId && snapshot.id === activeTransportSnapshotId));
            });
        });
        var transportRequestsForDestinationMarkers = computed(function () {
            var activeTransportSnapshotId = store.state.snapshots.activeTransportSnapshotId;
            return props.transports.filter(function (snapshot) {
                return snapshot.hasDestinationCoordinates &&
                    (snapshot.status === TransportRequestStatus.ON_BOARD ||
                        (activeTransportSnapshotId && snapshot.id === activeTransportSnapshotId));
            });
        });
        var fitAndPanToBounds = computed({
            get: function () {
                return store.state.map.fitAndPanToBounds;
            },
            set: function (value) {
                store.commit('map/setFitAndPanToBounds', value);
            },
        });
        var allMarkerBounds = computed(function () {
            if (googleApi.value === undefined) {
                return null;
            }
            if (!fitAndPanToBounds.value && !props.showMarkerBounds) {
                return null;
            }
            if (!props.positionedTeams.length && !props.transports.length) {
                return null;
            }
            var bounds = new googleApi.value.maps.LatLngBounds();
            props.positionedTeams.forEach(function (teamSnapshot) {
                var coordinates = new googleApi.value.maps.LatLng(teamSnapshot.position.lat, teamSnapshot.position.lng);
                bounds.extend(coordinates);
            });
            transportRequestsForOriginMarkers.value.forEach(function (transportSnapshot) {
                bounds.extend(new googleApi.value.maps.LatLng(transportSnapshot.origin.coordinates.lat, transportSnapshot.origin.coordinates.lng));
            });
            transportRequestsForDestinationMarkers.value.forEach(function (transportSnapshot) {
                bounds.extend(new googleApi.value.maps.LatLng(transportSnapshot.destination.coordinates.lat, transportSnapshot.destination.coordinates.lng));
            });
            return bounds.isEmpty() ? null : bounds;
        });
        watch(allMarkerBounds, function (newBounds) {
            if (!fitAndPanToBounds.value || !googleApi.value || !map.value) {
                return;
            }
            if (!newBounds) {
                return;
            }
            changingBounds.value = true;
            center.value = newBounds.getCenter();
            map.value.fitBounds(newBounds);
            map.value.panToBounds(newBounds);
            googleApi.value.maps.event.addListenerOnce(map.value, 'idle', function () {
                changingBounds.value = false;
            });
        });
        var boundsListener;
        function attachListeners() {
            var _a;
            boundsListener = (_a = map.value) === null || _a === void 0 ? void 0 : _a.addListener('bounds_changed', function () {
                if (!changingBounds.value) {
                    store.commit('map/setFitAndPanToBounds', false);
                }
            });
        }
        function detachListeners() {
            boundsListener === null || boundsListener === void 0 ? void 0 : boundsListener.remove();
        }
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            var _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // map needs to be updated first, else the watchers do not get called correctly and the map does not get positioned on the first load
                        _a = map;
                        return [4 /*yield*/, ((_c = mapRef.value) === null || _c === void 0 ? void 0 : _c.$mapPromise)];
                    case 1:
                        // map needs to be updated first, else the watchers do not get called correctly and the map does not get positioned on the first load
                        _a.value = _e.sent();
                        _b = googleApi;
                        return [4 /*yield*/, ((_d = mapRef.value) === null || _d === void 0 ? void 0 : _d.$gmapApiPromiseLazy())];
                    case 2:
                        _b.value = _e.sent();
                        googleApi.value.maps.event.addListenerOnce(map.value, 'idle', attachListeners);
                        return [2 /*return*/];
                }
            });
        }); });
        onBeforeUnmount(detachListeners);
        return {
            googleApi: googleApi,
            map: map,
            boundsListener: boundsListener,
            changingBounds: changingBounds,
            mapRef: mapRef,
            center: center,
            allMarkerBounds: allMarkerBounds,
            fitAndPanToBounds: fitAndPanToBounds,
            transportRequestsForOriginMarkers: transportRequestsForOriginMarkers,
            transportRequestsForDestinationMarkers: transportRequestsForDestinationMarkers,
        };
    },
    methods: {
        getTeamMarkerIcon: function (teamSnapshot) {
            var _a = useTeamSnapshotMarkerSpecs(teamSnapshot), path = _a.path, scale = _a.scale, fill = _a.fill, outline = _a.outline;
            return __assign({ path: path, fillColor: fill.color, fillOpacity: fill.opacity, strokeWeight: outline.weight, strokeColor: outline.color, scale: scale }, (this.googleApi && {
                anchor: new this.googleApi.maps.Point(19.5, 31),
                labelOrigin: new this.googleApi.maps.Point(19.5, 12.5),
            }));
        },
        getTeamMarkerLabel: function (teamSnapshot) {
            var label = useTeamSnapshotMarkerSpecs(teamSnapshot).label;
            return {
                text: label.text,
                color: label.color,
                fontFamily: label.fontFamily,
                fontSize: label.fontSize,
                fontWeight: label.fontWeight,
            };
        },
        getTransportMarkerIcon: function (transportSnapshot) {
            var isOrigin = true;
            var _a = useTransportSnapshotMarkerSpecs(transportSnapshot, isOrigin), path = _a.path, scale = _a.scale, fill = _a.fill, outline = _a.outline;
            return __assign({ path: path, fillColor: fill.color, fillOpacity: fill.opacity, strokeWeight: outline.weight, strokeColor: outline.color, scale: scale }, (this.googleApi && {
                anchor: new this.googleApi.maps.Point(17.5, 36),
                labelOrigin: new this.googleApi.maps.Point(17.5, 15),
            }));
        },
        getTransportDestinationMarkerIcon: function (transportSnapshot) {
            var isOrigin = false;
            var _a = useTransportSnapshotMarkerSpecs(transportSnapshot, isOrigin), path = _a.path, scale = _a.scale, fill = _a.fill, outline = _a.outline;
            return __assign({ path: path, fillColor: fill.color, fillOpacity: fill.opacity, strokeWeight: outline.weight, strokeColor: outline.color, scale: scale }, (this.googleApi && {
                anchor: new this.googleApi.maps.Point(17.5, 36),
                labelOrigin: new this.googleApi.maps.Point(17.5, 15),
            }));
        },
        getTransportMarkerLabel: function (transportSnapshot) {
            var label = useTransportSnapshotMarkerSpecs(transportSnapshot).label;
            return {
                text: label.text,
                color: label.color,
                fontFamily: label.fontFamily,
                fontSize: label.fontSize,
                fontWeight: label.fontWeight,
            };
        },
        getTeamKey: function (teamSnapshot) { return 'team-'.concat(teamSnapshot.id.toString()); },
        getTransportKey: function (transportSnapshot) { return 'transport-'.concat(transportSnapshot.id.toString()); },
        inspectTeam: function (teamSnapshot) {
            this.$emit('team:inspect', teamSnapshot);
        },
        inspectTransport: function (transportSnapshot) {
            this.$emit('transport:inspect', transportSnapshot);
        },
    },
});
