var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import TeamStatus, { TeamAvailableStatus } from '../../team/TeamStatus';
import TeamType from '../../team/TeamType';
import { defaultMarkerOptions, TEAM_DEFAULT_ICON_SCALE, TEAM_DEFAULT_LABEL, TEAM_DEFAULT_OUTLINE_WEIGHT, TEAM_FILL_COLOR, TEAM_FOCUSED_ICON_SCALE, TEAM_FOCUSED_OUTLINE_WEIGHT, TEAM_ICON_FILL_OPACITY, TEAM_ICON_PATH, TEAM_LABEL_COLOR, TEAM_LABEL_FONT_FAMILY, TEAM_LABEL_FONT_SIZE, TEAM_LABEL_FONT_WEIGHT, TEAM_OUTLINE_COLOR, } from './teamMarkerConfig';
var iconScale = function (isFocused) { return isFocused ? TEAM_FOCUSED_ICON_SCALE : TEAM_DEFAULT_ICON_SCALE; };
var fillColor = function (team) {
    switch (team.status) {
        case TeamStatus.AVAILABLE:
        case TeamAvailableStatus.AVAILABLE_IN_LOCATION:
        case TeamAvailableStatus.AVAILABLE_AT_COORDINATES:
        case TeamAvailableStatus.AVAILABLE_IN_INSTITUTION:
        case TeamAvailableStatus.AVAILABLE_IN_REGION:
            return TEAM_FILL_COLOR.BLUE;
        case TeamStatus.ASSIGNED:
            return TEAM_FILL_COLOR.GREEN;
        case TeamStatus.ON_BREAK:
            return TEAM_FILL_COLOR.RED;
        case TeamStatus.IN_RETURN:
            return TEAM_FILL_COLOR.ORANGE;
        case TeamStatus.NOT_DISSOLVED:
        default:
            return TEAM_FILL_COLOR.GRAY;
    }
};
var isVIP = function (team) { return team.type === TeamType.VIP; };
var outlineColor = function (team) { return (isVIP(team) ? TEAM_OUTLINE_COLOR.YELLOW : TEAM_OUTLINE_COLOR.BLACK); };
var outlineWeight = function (team, isFocused) {
    return (isVIP(team) || isFocused ? TEAM_FOCUSED_OUTLINE_WEIGHT : TEAM_DEFAULT_OUTLINE_WEIGHT);
};
var labelText = function (team) { return (team.number ? String(team.number) : TEAM_DEFAULT_LABEL); };
var labelColor = function (team) {
    var markerHasLightBackground = team.status === TeamStatus.IN_RETURN; // orange car
    return markerHasLightBackground ? TEAM_LABEL_COLOR.BLACK : TEAM_LABEL_COLOR.WHITE;
};
var useTeamMarkerSpecs = function (team, options) {
    if (options === void 0) { options = defaultMarkerOptions; }
    return {
        path: TEAM_ICON_PATH,
        scale: iconScale(options.isFocused),
        fill: {
            color: fillColor(team),
            opacity: TEAM_ICON_FILL_OPACITY,
        },
        outline: {
            color: outlineColor(team),
            weight: outlineWeight(team, options.isFocused),
            highlight: isVIP(team),
        },
        label: {
            text: labelText(team),
            fontFamily: TEAM_LABEL_FONT_FAMILY,
            fontSize: TEAM_LABEL_FONT_SIZE,
            fontWeight: TEAM_LABEL_FONT_WEIGHT,
            color: labelColor(team),
        },
    };
};
export var useTeamSnapshotMarkerSpecs = function (teamSnapshot) {
    var team = __assign(__assign({}, teamSnapshot), { type: teamSnapshot.teamType });
    var isFocused = teamSnapshot.isActive || false;
    var options = { isFocused: isFocused };
    return useTeamMarkerSpecs(team, options);
};
export default useTeamMarkerSpecs;
