/* istanbul ignore file */

export const TEAM_STATUS_KEY = 'status';
export const TEAM_NUMBER_KEY = 'teamNumber';

export const teamFilterOptions = {
  TEAM_STATUS_KEY,
  TEAM_NUMBER_KEY,
};

export const FILTER_TEAM_ALL = 'ALL';
export const FILTER_TEAM_AVAILABLE = 'AVAILABLE';
export const FILTER_TEAM_ELIGIBLE_FOR_ASSIGNMENT = 'ELIGIBLE_FOR_ASSIGNMENT';
export const FILTER_TEAM_NOT_DISSOLVED = 'NOT_DISSOLVED';
