<template>
  <div class="origin-and-destination-map field-box">
    <GMapMap
      ref="map"
      class="map-container"
      :center="center"
      :zoom="zoom"
      :options="{
        disableDefaultUI: true,
        zoomControl: true,
      }"
      @bounds_changed="mapMoved"
    >
      <GMapMarker v-if="hasOriginCoordinates()" key="origin" :position="origin.coordinates" icon="/img/map/red-marker.png" />
      <GMapMarker
        v-if="hasDestinationCoordinates()"
        key="destination"
        :position="destination.coordinates"
        icon="/img/map/blue-marker.png"
      />
    </GMapMap>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, toRef, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '../../store';

export default defineComponent({
  props: {
    origin: {
      type: Object,
      default: null,
    },
    destination: {
      type: Object,
      default: null,
    },
  },
  emits: ['boundsChange', 'setPlacesService'],
  setup(props, context) {
    const store = useStore(key);
    const map = ref(null);
    const googleApi = ref(null);
    const center = ref({ lat: 0, lng: 0 });
    const zoom = ref();
    const locations = computed(() => store.state.locations.data);

    function hasOriginCoordinates() {
      return !!(props.origin && props.origin.coordinates && props.origin.coordinates.lat && props.origin.coordinates.lng);
    }
    function hasDestinationCoordinates() {
      return !!(
        props.destination &&
        props.destination.coordinates &&
        props.destination.coordinates.lat &&
        props.destination.coordinates.lng
      );
    }

    watch(locations, () => {
      if (!hasOriginCoordinates() && !hasDestinationCoordinates()) {
        const activeLocations = locations.value.filter((location) => location.active);
        if (activeLocations.length > 0) {
          center.value = activeLocations[0].coordinates;
          zoom.value = 11;
        } else {
          center.value = { lat: 52.9399, lng: -73.5491 };
          zoom.value = 4;
        }
      }
    });

    function updateBounds() {
      if (map.value && googleApi.value) {
        // If only one place is selected
        if (hasOriginCoordinates() !== hasDestinationCoordinates()) {
          const place = hasOriginCoordinates() ? props.origin : props.destination;
          // During editing, we get the full viewport from the autocomplete widget
          if (place.viewport) {
            map.value.fitBounds(place.viewport);
            map.value.panToBounds(place.viewport);
          } else {
            // Else, center around the point, and zoom in reasonably
            center.value = place.coordinates;
            zoom.value = 17;
          }
        } else if (hasOriginCoordinates() && hasDestinationCoordinates()) {
          const bounds = new googleApi.value.maps.LatLngBounds();
          bounds.extend(props.origin.coordinates);
          bounds.extend(props.destination.coordinates);
          map.value.fitBounds(bounds);
          map.value.panToBounds(bounds);
        }
      }
    }

    const origin = toRef(props, 'origin');
    const destination = toRef(props, 'destination');
    watch([origin, destination], updateBounds);

    function mapMoved(bounds) {
      context.emit('boundsChange', bounds);
    }

    onMounted(async () => {
      const google = await map.value.$gmapApiPromiseLazy();
      googleApi.value = google;
      context.emit('setPlacesService', new google.maps.places.PlacesService(map.value.$mapObject));
      updateBounds();
    });
    onMounted(() => store.dispatch('locations/fetch'));

    return {
      map,
      center,
      zoom,
      hasOriginCoordinates,
      hasDestinationCoordinates,
      mapMoved,
    };
  },
});
</script>

<style>
.map-container {
  width: 100%;
  height: 400px;
}
</style>
