// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
[data-v-50d1a994] .n-tag {
  background-color: #e2e1e6;
  height: 22px;
  margin: auto;
}
[data-v-50d1a994] .n-base-selection {
  border-radius: 3px;
}
[data-v-50d1a994] .n-base-suffix__arrow {
  border-left: 1px solid #b3b3b9;
  height: 16px;
  padding-left: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/select/PiloteMultiSelect.vue"],"names":[],"mappings":";AA2BA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;AACd;AAEA;EACE,kBAAkB;AACpB;AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["<template>\n  <n-select v-model:value=\"selectedValues\" :placeholder=\"placeholder\" :options=\"options\" :multiple=\"true\" />\n</template>\n\n<script setup>\nimport { ref } from 'vue';\nimport { NSelect } from 'naive-ui';\n\nconst props = defineProps({\n  initialValue: {\n    type: Array,\n    default: () => [],\n  },\n  options: {\n    type: Array,\n    default: () => [],\n  },\n  placeholder: {\n    type: String,\n    default: () => '',\n  },\n});\n\nconst selectedValues = ref([props.initialValue]);\n</script>\n\n<style scoped>\n:deep(.n-tag) {\n  background-color: #e2e1e6;\n  height: 22px;\n  margin: auto;\n}\n\n:deep(.n-base-selection) {\n  border-radius: 3px;\n}\n\n:deep(.n-base-suffix__arrow) {\n  border-left: 1px solid #b3b3b9;\n  height: 16px;\n  padding-left: 3px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
