/* istanbul ignore file */
var module = {
    namespaced: true,
    state: {
        fitAndPanToBounds: true,
    },
    mutations: {
        setFitAndPanToBounds: function (state, fitAndPanToBounds) {
            state.fitAndPanToBounds = fitAndPanToBounds;
        },
    },
};
export default module;
