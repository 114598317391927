<template>
  <template v-if="isVIP">
    {{ $t('team.vip') }}
  </template>
  <template v-else>
    {{ $t('team.standard') }}
  </template>
  <template v-if="team.number">{{ $t('team.byNumber') }}{{ team.number }}</template>
  <template v-else>{{ $t('team.byId') }}{{ team.id }}</template>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { CHAR } from '../../constants';
import TeamType from './TeamType';

export default defineComponent({
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isVIP = computed(() => props.team.type === TeamType.VIP);

    return {
      isVIP,
      CHAR,
    };
  },
});
</script>
