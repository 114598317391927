<template>
  <n-config-provider :theme-overrides="naiveThemeConfig">
    <h1 v-if="readOnly">{{ $t('originAndDestination.route') }}</h1>

    <template v-else>
      <h1>{{ $t('originAndDestination.origin') }}</h1>

      <div class="switcher">
        <n-checkbox v-model:checked="originFromPlacesApi" class="dimmed" data-testid="places--origin-via-google-switcher">
          <small>{{ $t('originAndDestination.placesToggle') }}</small>
        </n-checkbox>
      </div>

      <div class="field-box">
        <div
          class="control-group control-group--flex inline-input span12"
          :style="originFromPlacesApi ? { display: 'flex', alignItems: 'center' } : {}"
        >
          <img src="/img/map/red-marker.png" class="img-scale" />

          <PlaceSelector
            v-if="originFromPlacesApi"
            data-testid="places--origin-search-input"
            :placeholder="$t('place.enterOrigin')"
            :bounds="mapBounds"
            @place-change="originChanged"
          />

          <div v-else class="establishment">
            <n-text class="establishment__label">{{ $t('originAndDestination.institution') }}</n-text>
            <n-select
              v-model:value="institutionValue"
              data-testid="places--institution-select"
              filterable
              :placeholder="$t('originAndDestination.placeholder')"
              :options="institutions"
              :loading="loading"
              remote
              :disabled="readOnly"
              @search="loadInstitutions"
              @update:value="setInstitutionOrigin"
            >
              <template #empty>
                <small class="remote-not-found">{{ $t('originAndDestination.emptyMessage') }}</small>
              </template>
            </n-select>
          </div>
        </div>
      </div>

      <h1>{{ $t('originAndDestination.destination') }}</h1>

      <div class="field-box">
        <div class="control-group control-group--flex inline-input span12">
          <img src="/img/map/blue-marker.png" />
          <PlaceSelector
            data-testid="places--destination-search-input"
            :placeholder="$t('place.enterDestination')"
            :bounds="mapBounds"
            @place-change="destinationChanged"
          />
        </div>
      </div>
    </template>

    <div class="field-box cards">
      <PlaceCard
        id="institution-name"
        :read-only="readOnly"
        :place="origin"
        img="/img/map/red-marker.png"
        @clear="clearOrigin()"
      />
      <PlaceCard :read-only="readOnly" :place="destination" img="/img/map/blue-marker.png" @clear="clearDestination()" />
    </div>

    <OriginAndDestinationMap
      :origin="origin"
      :destination="destination"
      @bounds-change="mapBoundsChanged"
      @set-places-service="setPlacesService"
    />

    <PlaceFields prefix="origin" :place="origin" />
    <PlaceFields prefix="destination" :place="destination" />
  </n-config-provider>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { NCheckbox, NSelect, NConfigProvider } from 'naive-ui';

import naiveThemeConfig from '../../config/naive-theme-config.json';
import { themeConfigAddendum } from '../../config/naive-ui';
import PlaceSelector from './PlaceSelector.vue';
import OriginAndDestinationMap from './OriginAndDestinationMap.vue';
import PlaceCard from './PlaceCard.vue';
import PlaceFields from './PlaceFields.vue';
import useInstitutions from './Institutions';

const EMPTY_PLACE = {
  streetAddress: '',
  city: '',
  province: '',
  postalCode: '',
  coordinates: null,
  formattedAddress: '',
  viewport: null,
};

export default defineComponent({
  components: {
    NCheckbox,
    NSelect,
    NConfigProvider,
    OriginAndDestinationMap,
    PlaceCard,
    PlaceSelector,
    PlaceFields,
  },
  inheritAttrs: false,
  setup(_, context) {
    const { t } = useI18n();
    const origin = ref(context.attrs.data.origin);
    const destination = ref(context.attrs.data.destination);
    const readOnly = ref(context.attrs.data.readOnly ?? false);
    const originFromPlacesApi = ref(true);
    const institutionValue = ref(null);
    const mapBounds = ref(null);
    const placeService = ref(null);

    const { institutions, loading, loadInstitutions, loadRegion } = useInstitutions();

    function clearOrigin() {
      if (!readOnly.value) {
        institutionValue.value = null;
        origin.value = { ...EMPTY_PLACE };
        loadInstitutions();
      }
    }

    function clearDestination() {
      if (!readOnly.value) {
        destination.value = { ...EMPTY_PLACE };
      }
    }

    function originChanged(place) {
      if (!readOnly.value) {
        if (place) {
          origin.value = place;
        } else {
          clearOrigin();
        }
      }
    }

    function destinationChanged(place) {
      if (!readOnly.value) {
        if (place) {
          destination.value = place;
        } else {
          clearDestination();
        }
      }
    }

    function mapBoundsChanged(bounds) {
      mapBounds.value = bounds;
    }

    function fetchGeometryWith(query, name) {
      if (!placeService.value) {
        throw new Error('Missing dependency: google.maps.places.PlacesService');
      }
      return new Promise((resolve) => {
        const request = {
          query,
          fields: ['name', 'geometry'],
        };

        placeService.value.findPlaceFromQuery(request, (results, status) => {
          if (/^ok/i.test(status) && results) {
            // Will resolve on 1st candidate if got only one, or cannot find by name
            if (results.length === 1 || !name) {
              resolve(results[0].geometry);
            } else if (results.length > 1) {
              // Will resolve on candidate that match
              const tokens = name.trim().toLowerCase().split(/\s*/).join('|');
              // Build an expression like: /'(\\b)(patente|et|machin)(\\b)/gi
              const re = new RegExp(`(\\b)(${tokens})(\\b)`, 'gi');
              let found = false;
              results.forEach((candidate) => {
                if (re.test(candidate.name)) {
                  found = true;
                  resolve(candidate.geometry);
                }
              });
              if (!found) {
                // If not found, just resolve on 1st candidate
                resolve(results[0].geometry);
              }
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        });
      });
    }

    async function setInstitutionOrigin(id, item) {
      const region = await loadRegion(item.institution.regionId);
      const address = `${item.institution.civicNumber} ${item.institution.street}`;
      const streetAddress = `${item.institution.name}, ${address}`;
      const formattedAddress = `${streetAddress}, ${region.city} ${region.province}`;

      let { lat, lng } = item.institution.coordinates;
      let { street } = item.institution;
      let viewport = null;
      if (item.institution.orientation) {
        const orient = t(`orientation.${item.institution.orientation}`);
        street = street.concat(` ${orient}`);
      }
      const query = `${item.institution.name}, ${item.institution.civicNumber} ${street} ${region.name},  ${region.province}`;
      const geometry = await fetchGeometryWith(query, item.institution.name);
      if (geometry) {
        lat = geometry.location.lat();
        lng = geometry.location.lng();
        viewport = geometry.viewport;
      }

      origin.value = {
        name: item.institution.name,
        formattedAddress,
        streetAddress,
        city: region.city,
        postalCode: '',
        province: region.province,
        coordinates: {
          lat,
          lng,
        },
        viewport,
      };
    }

    function setPlacesService(service) {
      placeService.value = service;
    }

    return {
      institutionValue,
      loading,
      institutions,
      origin,
      destination,
      readOnly,
      originFromPlacesApi,
      mapBounds,
      originChanged,
      destinationChanged,
      mapBoundsChanged,
      clearOrigin,
      clearDestination,
      setInstitutionOrigin,
      loadInstitutions,
      setPlacesService,
      naiveThemeConfig: {
        ...naiveThemeConfig,
        ...themeConfigAddendum,
      },
    };
  },
});
</script>

<style scoped>
.cards {
  display: flex;
}
.cards > div:first-child {
  margin-right: 10px;
}
.dimmed:not(:hover) small {
  opacity: 0.5;
}
.switcher {
  clear: left;
  margin: 1rem 0;
}
.control-group--flex {
  display: flex;
}
.img-scale {
  object-fit: scale-down;
}
.establishment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 95%;
}
.establishment__label {
  margin-right: 1ch;
}
.remote-not-found {
  text-align: left;
  width: 100%;
  opacity: 0.5;
}
</style>
