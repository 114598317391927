export var TEAM_ICON_PATH = "\n  M 14.5265 -2.8688\n  h -2.844l-1.463 -3.656\n  c -1.219 -3.313 -4.089 -6.188 -8.138 -6.188\n  h -9.163\n  c -4.048 0 -6.918 2.875 -8.139 6.188l-1.463 3.656\n  h -2.844\n  c -0.686 0 -1.19 0.645 -1.023 1.31l0.527 2.11\n  c 0.117 0.469 0.539 0.799 1.024 0.799\n  h 0.346\n  c -1.18 1.031 -1.94 2.529 -1.94 4.218\n  v 2.219\n  c 0 1.417 0.541 2.696 1.406 3.686\n  v 2.752\n  c 0 1.553 1.259 2.812 2.813 2.812\n  h 1.812\n  c 1.553 0 2.813 -1.259 2.813 -2.812\n  v -2.813\n  h 18.5\n  v 2.813\n  c 0 1.553 1.259 2.812 2.812 2.812\n  h 1.813\n  c 1.552 0 2.812 -1.259 2.812 -2.812\n  v -2.752\n  c 0.865 -0.989 1.406 -2.268 1.406 -3.686\n  v -2.219\n  c 0 -1.689 -0.76 -3.187 -1.939 -4.218\n  h 0.346\n  c 0.484 0 0.906 -0.33 1.023 -0.799l0.527 -2.11\n  c 0.166 -0.665 -0.338 -1.31 -1.024 -1.31\n  z\n";
export var TEAM_DEFAULT_ICON_SCALE = 1;
export var TEAM_FOCUSED_ICON_SCALE = 1.5;
export var TEAM_ICON_FILL_OPACITY = 1;
export var TEAM_FILL_COLOR = {
    BLUE: '#008cff',
    GRAY: '#808080',
    GREEN: '#008000',
    ORANGE: '#ffa500',
    RED: '#f00',
    WHITE: '#fff',
};
export var TEAM_DEFAULT_OUTLINE_WEIGHT = 1;
export var TEAM_FOCUSED_OUTLINE_WEIGHT = 2;
export var TEAM_OUTLINE_COLOR = {
    BLACK: '#000',
    YELLOW: '#ff0',
};
export var TEAM_DEFAULT_LABEL = ' ';
export var TEAM_LABEL_OFFSET = { x: -2, y: 0 };
export var TEAM_LABEL_COLOR = {
    BLACK: '#000',
    WHITE: '#fff',
};
export var TEAM_LABEL_FONT_FAMILY = "'Open Sans', sans-serif";
export var TEAM_LABEL_FONT_SIZE = '15px';
export var TEAM_LABEL_FONT_WEIGHT = '600';
export var defaultMarkerOptions = {
    isFocused: false,
};
export var TEAM_EXCLUSION_MARKER_OFFSET = { x: 14, y: -18 };
export var TEAM_ELECTRIC_MARKER_OFFSET = { x: -15, y: -12 };
