<template>
  <BaseAssignmentTableRow :transport="transport" :show-team-column="false" :show-passengers-column="true">
    <template #awaiting-time>
      <TextData v-if="formattedWaitingTime" variant="xs">
        {{ formattedWaitingTime }} {{ t('assignment.table.abbreviatedMinutes') }}
      </TextData>
    </template>
  </BaseAssignmentTableRow>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

import { millisecondsToMinutes } from '../../../../helpers/Converters';
import { formatNumber } from '../../../../helpers/Formatters';

import BaseAssignmentTableRow from './BaseAssignmentTableRow.vue';
import TextData from '../TextData.vue';

const { t, locale } = useI18n({ useScope: 'global' });

const props = defineProps({
  transport: {
    type: Object,
    required: true,
  },
});

const formattedWaitingTime = computed(() => {
  if (props.transport.createdOn) {
    const waitingTime = millisecondsToMinutes(Date.now() - props.transport.createdOn);
    return formatNumber(waitingTime, locale.value);
  }
  return '';
});
</script>
