/* istanbul ignore file */

import { reactive, toRefs } from 'vue';
import axios from 'axios';
import { AXIOS_CONFIG } from '../../constants';

export default function useInstitutions() {
  const state = reactive({
    institutions: [],
    loading: false,
  });

  const loadRegion = async (regionId) => {
    const url = `/app/api/region/${regionId}`;
    const response = await axios.get(url, AXIOS_CONFIG);
    return response.data;
  };

  const loadInstitutions = async (name) => {
    if (!name) {
      state.institutions = [];
      return;
    }
    const url = `/app/api/institution?name=${encodeURIComponent(name)}`;
    state.loading = true;
    try {
      const response = await axios.get(url, AXIOS_CONFIG);
      const institutions = response.data;
      if (institutions.length) {
        state.institutions = institutions.map((institution) => ({
          label: institution.name,
          value: institution.id,
          institution,
        }));
      } else {
        state.institutions = [];
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error Loading institutions');
    }
    state.loading = false;
  };

  return { ...toRefs(state), loadInstitutions, loadRegion };
}
