import { computed, defineComponent } from 'vue';
import { NButton } from 'naive-ui';
import prettyMS from 'pretty-ms';
import { useStore } from 'vuex';
import { key } from '../../store';
import { CHAR } from '../../constants';
import { transportIdToLetters } from '../../helpers/Converters';
import { formatAddress, formatCoordinates } from '../../helpers/Formatters';
import OriginDestination from '../assignment/assignmentTable/OriginDestination.vue';
import TeamMarker from '../generic/team/TeamMarker.vue';
import TextData from '../assignment/assignmentTable/TextData.vue';
import TransportMarker from '../generic/transport/TransportMarker.vue';
export default defineComponent({
    components: { TeamMarker: TeamMarker, OriginDestination: OriginDestination, TextData: TextData, TransportMarker: TransportMarker, NButton: NButton },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: function () { return false; },
        },
        timestamp: {
            type: Number,
            default: function () { return null; },
        },
    },
    emits: ['transport:edit', 'transport:delete', 'transport:inspect', 'team:inspect'],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore(key);
        var teamsById = computed(function () { return store.state.snapshots.teamsById; });
        var assignmentsMap = computed(function () { return store.state.autoAssignments.assignments; });
        var printNbPlural = function (num, item) {
            if (!num) {
                return '';
            }
            if (num > 1) {
                return "".concat(num).concat(CHAR.NBSP).concat(item, "s");
            }
            return "".concat(num).concat(CHAR.NBSP).concat(item);
        };
        var num = computed(function () { return transportIdToLetters(props.transport.id); });
        var assignedTeam = computed(function () {
            if (props.transport.teamId) {
                return teamsById.value.get(props.transport.teamId);
            }
            return undefined;
        });
        var autoAssignedTeam = computed(function () {
            var autoAssignment = assignmentsMap.value.get(props.transport.id);
            if (autoAssignment) {
                return autoAssignment.team;
            }
            return undefined;
        });
        var origin = computed(function () {
            return formatAddress(props.transport.origin.streetAddress, props.transport.origin.city, props.transport.origin.province);
        });
        var destination = computed(function () {
            return formatAddress(props.transport.destination.streetAddress, props.transport.destination.city);
        });
        var nbOfSeatBelts = computed(function () { return printNbPlural(props.transport.nbOfSeatBelts, 'place'); });
        var nbOfPassengers = computed(function () { return printNbPlural(props.transport.nbOfPassengers, 'client'); });
        var waiting = computed(function () {
            if (props.transport.createdOn && props.timestamp) {
                return prettyMS(props.timestamp - props.transport.createdOn, { unitCount: 2 });
            }
            return '';
        });
        var onEdit = function () { return emit('transport:edit', props.transport); };
        var onDelete = function () { return emit('transport:delete', props.transport); };
        var onInspect = function () { return emit('transport:inspect', props.transport); };
        var onInspectTeam = function (team) { return emit('team:inspect', team); };
        var onSelectedRow = function () { return store.dispatch('snapshots/toggleActiveTransportSnapshotId', props.transport.id); };
        return {
            num: num,
            assignedTeam: assignedTeam,
            autoAssignedTeam: autoAssignedTeam,
            origin: origin,
            destination: destination,
            waiting: waiting,
            nbOfSeatBelts: nbOfSeatBelts,
            nbOfPassengers: nbOfPassengers,
            onEdit: onEdit,
            onDelete: onDelete,
            onInspect: onInspect,
            onInspectTeam: onInspectTeam,
            onSelectedRow: onSelectedRow,
            formatCoordinates: formatCoordinates,
        };
    },
});
