/* istanbul ignore file */

export const TRANSPORT_BASE_NUMBERS_KEY = 'baseNumbers';
export const TRANSPORT_STATUS_KEY = 'status';
export const CLIENT = 'client';
export const CREATION_DATE = 'creationDate';
export const EVENING_NUMBER = 'eveningNumber';
export const INSTITUTION_ID = 'institutionId';
export const SHOW_ALL_OPEN_REQUESTS = 'showAllOpenRequests';
export const TEAM_ID = 'teamId';
export const TEAM_NUMBER = 'teamNumber';
export const TEAM_STARTING_LOCATION_ID = 'teamStartingLocationId';
export const TRANSPORT_REQUEST_ID = 'transportRequestId';

export const transportFilterOptions = {
  TRANSPORT_BASE_NUMBERS_KEY,
  TRANSPORT_STATUS_KEY,
  CLIENT,
  CREATION_DATE,
  EVENING_NUMBER,
  INSTITUTION_ID,
  SHOW_ALL_OPEN_REQUESTS,
  TEAM_ID,
  TEAM_NUMBER,
  TEAM_STARTING_LOCATION_ID,
  TRANSPORT_REQUEST_ID,
};

export const FILTER_TRANSPORT_STATUS_ALL_OPEN = 'ALL_OPEN_REQUESTS';
export const FILTER_TRANSPORT_STATUS_PENDING = 'PENDING';
