<template>
  <div class="header-row">
    <div class="assignment-row-transport-marker">
      <AssignmentTableHeaderCell :title="firstHeaderLabel" style="padding-left: 13px" />
    </div>

    <div class="assignment-row-wait-time">
      <AssignmentTableHeaderCell :title="secondHeaderLabel" />
    </div>

    <div class="assignment-row-route-information">
      <AssignmentTableHeaderCell :title="thirdHeaderLabel" />
    </div>

    <div v-if="showPassengersColumn" class="assignment-row-number-passengers">
      <AssignmentTableHeaderCell :title="sixthHeaderLabel" />
    </div>

    <div v-if="showTeamColumn" class="assignment-row-team-marker">
      <AssignmentTableHeaderCell :title="fifthHeaderLabel" />
    </div>

    <div class="assignment-row-origin-destination-header">
      <AssignmentTableHeaderCell :title="fourthHeaderLabel" />
    </div>
  </div>
</template>

<script setup>
import AssignmentTableHeaderCell from '../AssignmentTableHeaderCell';

const props = defineProps({
  headerLabels: {
    type: Array,
    required: true,
  },
  showTeamColumn: {
    type: Boolean,
    default: true,
  },
  showPassengersColumn: {
    type: Boolean,
    default: false,
  },
});

const [
  firstHeaderLabel = '',
  secondHeaderLabel = '',
  thirdHeaderLabel = '',
  fourthHeaderLabel = '',
  fifthHeaderLabel = '',
  sixthHeaderLabel = ''
] = props.headerLabels;
</script>

<style scoped>
.header-row {
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #e2e1e5;
  padding-left: 2px;
}
</style>
