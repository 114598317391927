/* istanbul ignore file */
import { createStore } from 'vuex';
import institutions from './modules/institutions';
import locations from './modules/locations';
import map from './modules/map';
import teams from './modules/teams';
import snapshots from './modules/snapshots';
import transports from './modules/transports';
import autoAssignments from './modules/auto-assignments';
import assignmentPage from "./modules/assignmentPage";
export var key = Symbol();
export var store = createStore({
    modules: {
        institutions: institutions,
        locations: locations,
        map: map,
        teams: teams,
        snapshots: snapshots,
        transports: transports,
        autoAssignments: autoAssignments,
        assignmentPage: assignmentPage,
    },
});
