<template>
  <input :value="place.streetAddress" type="hidden" :name="prefix + '.streetAddress'" />
  <input :value="place.city" type="hidden" :name="prefix + '.city'" />
  <input :value="place.province" type="hidden" :name="prefix + '.province'" />
  <input :value="place.postalCode" type="hidden" :name="prefix + '.postalCode'" />
  <input :value="place.placeDetails" type="hidden" :name="prefix + '.placeDetails'" />

  <input v-if="place.coordinates" :value="place.coordinates.lat" type="hidden" :name="prefix + '.coordinates.lat'" />
  <input v-if="place.coordinates" :value="place.coordinates.lng" type="hidden" :name="prefix + '.coordinates.lng'" />
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    prefix: {
      type: String,
      required: true,
    },
    place: {
      type: Object,
      required: true,
    },
  },
});
</script>
