// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.progress-ring[data-v-693bd1ac] {
  height: 16px;
  width: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/progress/PiloteProgress.vue"],"names":[],"mappings":";AAuBA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["<template>\n  <n-progress\n    class=\"progress-ring\"\n    color=\"#152943\"\n    type=\"circle\"\n    :show-indicator=\"false\"\n    :percentage=\"percentage\"\n    :stroke-width=\"10\"\n  />\n</template>\n\n<script setup>\nimport { NProgress } from 'naive-ui';\n\ndefineProps({\n  percentage: {\n    type: Number,\n    required: true,\n  },\n});\n</script>\n\n<style scoped>\n.progress-ring {\n  height: 16px;\n  width: 16px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
