<template>
  <n-config-provider :theme-overrides="naiveThemeConfig">
    <PlaceSelector v-if="addressEmpty()" data-testid="candidate-address-selector" @place-change="addressChanged" />
    <PlaceCard v-else :read-only="false" :place="address" @clear="clearAddress()" />
    <PlaceFields prefix="address" :place="address" />
  </n-config-provider>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NConfigProvider } from 'naive-ui';
import { isEqual } from 'lodash';

import naiveThemeConfig from '../../config/naive-theme-config.json';
import { themeConfigAddendum } from '../../config/naive-ui';
import PlaceCard from './PlaceCard.vue';
import PlaceSelector from './PlaceSelector.vue';
import PlaceFields from './PlaceFields.vue';

const EMPTY_PLACE = {
  streetAddress: '',
  city: '',
  province: '',
  postalCode: '',
  coordinates: null,
  formattedAddress: '',
  viewport: null,
};

export default defineComponent({
  components: {
    NConfigProvider,
    PlaceCard,
    PlaceSelector,
    PlaceFields,
  },
  inheritAttrs: false,
  setup(_, context) {
    const address = ref(context.attrs.data.address);

    function clearAddress() {
      address.value = { ...EMPTY_PLACE };
    }

    function addressChanged(newAddress) {
      if (newAddress) {
        address.value = newAddress;
      } else {
        clearAddress();
      }
    }

    function addressEmpty() {
      const empty =
        isEqual(address.value, EMPTY_PLACE) ||
        address.value.city === null ||
        address.value.streetAddress === null ||
        address.value.postalCode === null ||
        address.value.province === null;
      return empty;
    }

    return {
      address,
      addressChanged,
      addressEmpty,
      clearAddress,
      naiveThemeConfig: {
        ...naiveThemeConfig,
        ...themeConfigAddendum,
      },
    };
  },
});
</script>
