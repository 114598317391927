/* istanbul ignore file */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApp } from 'vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import { key, store } from './store';
import { i18nConfig } from './config/i18n';
import { createNaiveUI } from './config/naive-ui';
import MapPage from './components/map/MapPage.vue';
import AssignmentPage from './components/assignment/AssignmentPage.vue';
import OriginAndDestinationEditor from './components/place/OriginAndDestinationEditor.vue';
import DispatchPage from './components/assignmentPrototype/DispatchPage.vue';
import CandidateAddressEditor from './components/place/CandidateAddressEditor.vue';
var vueComponents = {
    '#map-page': MapPage,
    '#origin-destination-editor': OriginAndDestinationEditor,
    '#assignment-page': AssignmentPage,
    '#dispatch-page': DispatchPage,
    '#candidate-address-editor': CandidateAddressEditor,
};
Object.entries(vueComponents).forEach(function (_a) {
    var id = _a[0], component = _a[1];
    var element = document.querySelector(id);
    if (element) {
        var dataString = element.getAttribute('data-vue-data');
        var locale = element.getAttribute('data-vue-locale') || 'fr';
        var attrs = dataString ? { data: JSON.parse(dataString) } : {};
        var i18n = createI18n(__assign(__assign({}, i18nConfig), { locale: locale || 'fr' }));
        var app = createApp(component, attrs);
        app.use(store, key);
        app.use(i18n);
        app.use(createNaiveUI());
        app.use(VueGoogleMaps, {
            load: {
                key: process.env.GCP_API_KEY,
                libraries: ['places'],
                language: "".concat(locale, "-CA"),
                region: 'CA',
            },
        });
        if (process.env.SENTRY_DSN) {
            console.log('Sentry enabled');
            Sentry.init({
                app: app,
                dsn: process.env.SENTRY_DSN,
                integrations: [new Sentry.Replay()],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                enableTracing: false,
                tracesSampleRate: 0,
                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 1.0,
            });
        }
        app.mount(element);
    }
});
