<template>
  <div class="form-search filter-block no-print">
    <div class="pad-wrapper">
      <n-grid :x-gap="12" :y-gap="8" :cols="7">
        <!-- ROW 1 -->
        <n-grid-item span="2">
          <n-select
            v-model:value="transportFilter[transportFilterOptions.TRANSPORT_STATUS_KEY]"
            :options="transportStatusesOptions"
          />
        </n-grid-item>
        <n-grid-item span="2">
          <n-input
            v-model:value="transportFilter[transportFilterOptions.TRANSPORT_REQUEST_ID]"
            :placeholder="t('map.placeholder.transportRequestId')"
          >
            <template #suffix>
              <i class="fas fa-search" style="color: var(--placeholder-color)" />
            </template>
          </n-input>
        </n-grid-item>
        <n-grid-item span="2">
          <n-select
            v-model:value="transportFilter[transportFilterOptions.TRANSPORT_BASE_NUMBERS_KEY]"
            :options="baseNumbersOptions"
            :placeholder="t('map.placeholder.baseNumber')"
            multiple
            clearable
          />
        </n-grid-item>
        <n-grid-item>
          <button id="apply-filter-button" class="btn-flat report report-refresh" @click="submit">
            <i class="fas fa-search"></i>
          </button>
        </n-grid-item>

        <!-- ROW 2 -->
        <n-grid-item span="2">
          <onr-auto-complete
            v-model:value="transportFilter[transportFilterOptions.INSTITUTION_ID]"
            :options="institutions"
            :placeholder="t('map.select.institution')"
          />
        </n-grid-item>
        <n-grid-item span="2">
          <n-input v-model:value="teamFilter[teamFilterOptions.TEAM_NUMBER_KEY]" :placeholder="t('map.placeholder.team.number')">
            <template #suffix>
              <i class="fas fa-search" style="color: var(--placeholder-color)" />
            </template>
          </n-input>
        </n-grid-item>
        <n-grid-item span="2">
          <n-input
            v-model:value="transportFilter[transportFilterOptions.EVENING_NUMBER]"
            :placeholder="t('map.placeholder.evening.number')"
          >
            <template #suffix>
              <i class="fas fa-search" style="color: var(--placeholder-color)" />
            </template>
          </n-input>
        </n-grid-item>
        <n-grid-item />

        <!-- ROW 3 -->
        <n-grid-item span="2">
          <n-select
            v-model:value="transportFilter[transportFilterOptions.TEAM_STARTING_LOCATION_ID]"
            :options="locationsOptions"
            :placeholder="t('map.select.location')"
            clearable
          />
        </n-grid-item>
        <n-grid-item span="2">
          <n-input v-model:value="transportFilter[transportFilterOptions.CLIENT]" :placeholder="t('map.placeholder.client')">
            <template #suffix>
              <i class="fas fa-search" style="color: var(--placeholder-color)" />
            </template>
          </n-input>
        </n-grid-item>
        <n-grid-item span="3" />

        <!-- ROW 4 -->
        <n-grid-item span="2">
          <n-select
            v-model:value="teamFilter[teamFilterOptions.TEAM_STATUS_KEY]"
            :options="teamStatusesOptions"
            :placeholder="t('map.select.teamStatus')"
          />
        </n-grid-item>
      </n-grid>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';
import { NGrid, NGridItem, NSelect, NInput } from 'naive-ui';

import OnrAutoComplete from '../autocomplete/OnrAutoComplete.vue';
import TeamStatus from '../team/TeamStatus';
import TransportRequestStatus from '../transport/TransportStatus';
import { TEAM_NUMBER_KEY, teamFilterOptions } from '../../store/collections/teamQueryFields';
import { transportFilterOptions } from '../../store/collections/transportQueryFields';

export default defineComponent({
  components: { OnrAutoComplete, NGrid, NGridItem, NSelect, NInput },
  emits: ['refresh', 'init'],
  setup() {
    const { t } = useI18n({ useScope: 'global' });

    const valueRef = ref(null);
    return {
      value: valueRef,
      t,
    };
  },
  data() {
    return {
      teamFilterOptions,
      transportFilterOptions,
    };
  },
  computed: {
    ...mapState({
      institutions: (state) => state.institutions.data,
      locations: (state) => state.locations.data,
      teamFilter: (state) => state.teams.filter,
      transportFilter: (state) => state.transports.filter,
    }),
    transportStatusesOptions(proxy) {
      return Object.entries(TransportRequestStatus).map(([key, value]) => ({
        label: proxy.t('transport.status.'.concat(key)),
        value,
      }));
    },
    baseNumbersOptions() {
      return Array.from({ length: 100 }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      }));
    },
    locationsOptions() {
      return this.locations.map((value) => ({
        label: value.name,
        value: value.id,
      }));
    },
    teamStatusesOptions(proxy) {
      return Object.entries(TeamStatus).map(([key, value]) => ({
        label: proxy.t('team.status.'.concat(key)),
        value,
      }));
    },
  },
  async mounted() {
    this.$emit('init', { teamFilter: this.teamFilter, transportFilter: this.transportFilter });
    const urlParams = new URLSearchParams(window.location.search);
    const teamNumber = urlParams.get('team.teamNumber');
    if (teamNumber) {
      await this.$store.dispatch('teams/setFilter', { [TEAM_NUMBER_KEY]: teamNumber });
    }
    await this.$store.dispatch('institutions/fetch');
    await this.$store.dispatch('locations/fetch');
  },
  methods: {
    async submit() {
      this.$emit('refresh', { teamFilter: this.teamFilter, transportFilter: this.transportFilter });
      await this.$store.dispatch('teams/fetch');
      await this.$store.dispatch('transports/fetch');
    },
  },
});
</script>

<style scoped>
.pad-wrapper {
  padding: 0 25px;
  margin-bottom: 0.5rem;
}
</style>
