var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultMarkerOptions, TRANSPORT_DEFAULT_ICON_SCALE, TRANSPORT_DEFAULT_OUTLINE_WEIGHT, TRANSPORT_DESTINATION_ICON_PATH, TRANSPORT_FILL_COLOR, TRANSPORT_FOCUSED_ICON_SCALE, TRANSPORT_FOCUSED_OUTLINE_WEIGHT, TRANSPORT_ICON_FILL_OPACITY, TRANSPORT_LABEL_COLOR, TRANSPORT_LABEL_FONT_FAMILY, TRANSPORT_LABEL_FONT_SIZE, TRANSPORT_LABEL_FONT_WEIGHT, TRANSPORT_ORIGIN_ICON_PATH, TRANSPORT_OUTLINE_COLOR, } from './transportMarkerConfig';
import TransportStatus, { ALL_OPEN_REQUESTS } from '../../transport/TransportStatus';
import { transportIdToLetters } from '../../../helpers/Converters';
var iconPath = function (transport, isOrigin) {
    return isOrigin ? TRANSPORT_ORIGIN_ICON_PATH : TRANSPORT_DESTINATION_ICON_PATH;
};
var iconScale = function (isFocused) {
    return (isFocused ? TRANSPORT_FOCUSED_ICON_SCALE : TRANSPORT_DEFAULT_ICON_SCALE);
};
var iconOpacity = function () { return TRANSPORT_ICON_FILL_OPACITY; };
var fillColor = function (transport) {
    switch (transport.status) {
        case TransportStatus.PENDING:
            return TRANSPORT_FILL_COLOR.BLUEBERRY_BLUE;
        case TransportStatus.ASSIGNED:
            return TRANSPORT_FILL_COLOR.BASHFUL_PINK;
        case TransportStatus.ALLOCATED:
            return TRANSPORT_FILL_COLOR.GREY;
        case TransportStatus.ON_BOARD:
            return TRANSPORT_FILL_COLOR.AO_GREEN;
        case TransportStatus.COMPLETED:
        case TransportStatus.TRANSFERRED:
            return TRANSPORT_FILL_COLOR.PRUSSIAN_BLUE;
        case TransportStatus.CANCELLED:
        case TransportStatus.UNFINDABLE:
        case ALL_OPEN_REQUESTS:
            return TRANSPORT_FILL_COLOR.PERSIAN_RED;
        default:
            return TRANSPORT_FILL_COLOR.GRANITE_GRAY;
    }
};
var outlineColor = function () { return TRANSPORT_OUTLINE_COLOR.BLACK; };
var outlineWeight = function (isFocused) {
    return (isFocused ? TRANSPORT_FOCUSED_OUTLINE_WEIGHT : TRANSPORT_DEFAULT_OUTLINE_WEIGHT);
};
var labelText = function (transport) { return transportIdToLetters(transport.transportRequestId); };
var labelFontFamily = function () { return TRANSPORT_LABEL_FONT_FAMILY; };
var labelFontSize = function () { return TRANSPORT_LABEL_FONT_SIZE; };
var labelFontWeight = function () { return TRANSPORT_LABEL_FONT_WEIGHT; };
var labelColor = function () { return TRANSPORT_LABEL_COLOR.WHITE; };
var useTransportMarkerSpecs = function (transport, options) {
    if (options === void 0) { options = defaultMarkerOptions; }
    return ({
        path: iconPath(transport, options.isOrigin),
        scale: iconScale(options.isFocused),
        fill: {
            color: fillColor(transport),
            opacity: iconOpacity(),
        },
        outline: {
            color: outlineColor(),
            weight: outlineWeight(options.isFocused),
        },
        label: {
            text: labelText(transport),
            fontFamily: labelFontFamily(),
            fontSize: labelFontSize(),
            fontWeight: labelFontWeight(),
            color: labelColor(),
        },
    });
};
export var useTransportSnapshotMarkerSpecs = function (transportSnapshot, isOrigin) {
    if (isOrigin === void 0) { isOrigin = true; }
    var transport = __assign(__assign({}, transportSnapshot), { transportRequestId: transportSnapshot.id });
    var isFocused = transportSnapshot.isActive || false;
    var options = { isFocused: isFocused, isOrigin: isOrigin };
    return useTransportMarkerSpecs(transport, options);
};
export default useTransportMarkerSpecs;
