var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, defineComponent, reactive, ref, toRef, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { key } from '../../store';
import { NButton, NConfigProvider, NIcon, NText } from 'naive-ui';
import { FullscreenExitOutlined as MinimizeIcon, FullscreenOutlined as MaximizeIcon } from '@vicons/antd';
import naiveThemeConfig from '../../config/naive-theme-config.json';
import { themeConfigAddendum } from '../../config/naive-ui';
import { CHAR } from '../../constants';
import { transportIdToLetters } from '../../helpers/Converters';
import { formatAddress } from '../../helpers/Formatters';
import SnapshotsMapView from './SnapshotsMapView.vue';
import TeamLabel from '../team/TeamLabel.vue';
import TransportMarker from '../generic/transport/TransportMarker.vue';
import TransportTable from './TransportTable.vue';
import ChronoSlider from './ChronoSlider.vue';
import JsonViewer from './JsonViewer.vue';
var dateFormatter = new Intl.DateTimeFormat('fr-CA', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
});
var isARef = function (obj) { return (obj === null || obj === void 0 ? void 0 : obj.value) !== undefined; };
export default defineComponent({
    components: {
        ChronoSlider: ChronoSlider,
        JsonViewer: JsonViewer,
        SnapshotsMapView: SnapshotsMapView,
        TransportMarker: TransportMarker,
        TransportTable: TransportTable,
        NConfigProvider: NConfigProvider,
        NButton: NButton,
        NText: NText,
        NIcon: NIcon,
        MaximizeIcon: MaximizeIcon,
        MinimizeIcon: MinimizeIcon,
        TeamLabel: TeamLabel,
    },
    inheritAttrs: false,
    setup: function (_, context) {
        var _this = this;
        var _a;
        var store = useStore(key);
        var data = context.attrs.data;
        var payload = reactive({
            centralId: data.centralId,
            campaignId: data.campaignId,
            eveningId: data.eveningId,
        });
        var timestamp = ref(0);
        var fullScreenPageMode = ref(false);
        var inspected = ref(undefined);
        // Will receive data from Storybook as a ref or webpage as a value (thus needs refing):
        var eveningDate = isARef(data.eveningDate) ? data.eveningDate : ref(data.eveningDate);
        // Start the evening at 20:00 PM:
        var startsAt = computed(function () {
            if (!eveningDate.value) {
                return undefined;
            }
            // Will receive eveningDate as Array<Number>: [YYYY, MM, DD]:
            var _a = eveningDate.value, year = _a[0], month = _a[1], day = _a[2];
            return new Date(year, month - 1, day, 19).getTime();
        });
        // End the evening at 4:30 AM:
        var endsAt = computed(function () {
            if (!eveningDate.value) {
                return undefined;
            }
            // Will receive endsAt as Array<Number>: [YYYY, MM, DD]:
            var _a = eveningDate.value, year = _a[0], month = _a[1], day = _a[2];
            return new Date(year, month - 1, day, 28, 30).getTime();
        });
        var timestamps = computed(function () { return store.state.snapshots.timestamps; });
        var positionedTeams = computed(function () { return store.state.snapshots.teams.filter(function (team) { return team.hasPosition; }); });
        var unPositionedTeams = computed(function () { return store.state.snapshots.teams.filter(function (team) { return !team.hasPosition; }); });
        var transports = computed(function () { return store.state.snapshots.transports; });
        var transportsById = computed(function () { return store.state.snapshots.transportsById; });
        var formattedDate = computed(function () { return (startsAt.value ? dateFormatter.format(new Date(startsAt.value)) : ''); });
        function onChangeTimestamp(value) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            timestamp.value = value;
                            return [4 /*yield*/, store.dispatch('snapshots/fetchTimestamp', value)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function fetchAutoAssignment() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch('autoAssignments/fetch', timestamp.value)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function reset() {
            store.dispatch('autoAssignments/reset');
        }
        function setTeamInspected(team) {
            inspected.value = {
                type: 'Team',
                body: team,
            };
        }
        function setTransportRequestInspected(transport) {
            inspected.value = {
                type: 'TransportRequest',
                num: transportIdToLetters(transport.id),
                title: formatAddress(transport.origin.streetAddress, transport.origin.city, transport.origin.province),
                body: transport,
            };
        }
        function fetchTimestamps() {
            return __awaiter(this, void 0, void 0, function () {
                var from, to;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            from = startsAt.value;
                            to = endsAt.value;
                            return [4 /*yield*/, store.dispatch('snapshots/submitPeriod', { from: from, to: to })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function toggleFullScreen() {
            fullScreenPageMode.value = !fullScreenPageMode.value;
            // eslint-disable-next-line
            fullScreenPageMode.value === true
                ? document.body.classList.add('full-screen-no-scroll')
                : document.body.classList.remove('full-screen-no-scroll');
        }
        var eveningId = toRef(payload, 'eveningId');
        var pageUrl = new URL(window.location.href);
        var urlValue = parseFloat((_a = pageUrl.searchParams.get('transportRequestId')) !== null && _a !== void 0 ? _a : '');
        if (urlValue) {
            var setActiveTransport = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log('setActiveTransport');
                            if (!transportsById.value.has(urlValue)) return [3 /*break*/, 2];
                            console.log('setActiveTransport: found id in map');
                            return [4 /*yield*/, store.dispatch('snapshots/toggleActiveTransportSnapshotId', urlValue)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, true];
                        case 2: return [2 /*return*/, false];
                    }
                });
            }); };
            if (!setActiveTransport()) {
                var stop = watchEffect(setActiveTransport);
                stop();
            }
        }
        var activeTransportSnapshotId = computed(function () { return store.state.snapshots.activeTransportSnapshotId; });
        watch(function () { return activeTransportSnapshotId; }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                pageUrl = new URL(window.location.href);
                if (activeTransportSnapshotId.value) {
                    pageUrl.searchParams.set('transportRequestId', activeTransportSnapshotId.value.toString());
                }
                else {
                    console.log('Deleting search param');
                    pageUrl.searchParams.delete('transportRequestId');
                }
                window.history.replaceState(null, '', pageUrl.href);
                console.log("Page URL: ".concat(pageUrl.href));
                return [2 /*return*/];
            });
        }); });
        watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(eveningId.value > 0 && startsAt.value)) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchTimestamps()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
        watch(timestamp, function (newValue, oldValue) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newValue !== oldValue)) return [3 /*break*/, 2];
                        return [4 /*yield*/, store.dispatch('autoAssignments/reset')];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
        return {
            fullScreenPageMode: fullScreenPageMode,
            toggleFullScreen: toggleFullScreen,
            eveningId: eveningId,
            startsAt: startsAt,
            endsAt: endsAt,
            timestamps: timestamps,
            timestamp: timestamp,
            positionedTeams: positionedTeams,
            unPositionedTeams: unPositionedTeams,
            transports: transports,
            formattedDate: formattedDate,
            inspected: inspected,
            setTeamInspected: setTeamInspected,
            setTransportRequestInspected: setTransportRequestInspected,
            isEditable: false,
            onChangeTimestamp: onChangeTimestamp,
            fetchAutoAssignment: fetchAutoAssignment,
            reset: reset,
            naiveThemeConfig: __assign(__assign({}, naiveThemeConfig), themeConfigAddendum),
            CHAR: CHAR,
        };
    },
});
