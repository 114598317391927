// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gm-ui-hover-effect:focus {
  outline: none !important;
}
.tooltip-exit-button-mask {
  height: 15px;
  width: 15px;
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/map/tooltip/MapMarkerTooltip.vue"],"names":[],"mappings":";AA4BA;EACE,wBAAwB;AAC1B;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,aAAa;AACf","sourcesContent":["<template>\n  <GMapInfoWindow :position=\"position\" :opened=\"isVisible\">\n    <div>{{ text }}</div>\n    <div class=\"tooltip-exit-button-mask\"></div>\n  </GMapInfoWindow>\n</template>\n\n<script setup lang=\"ts\">\nimport { PropType } from 'vue';\nimport { Coordinates } from '../../../../models/common';\n\ndefineProps({\n  text: {\n    type: String,\n    required: true,\n  },\n  position: {\n    type: Object as PropType<Coordinates>,\n    required: true,\n  },\n  isVisible: {\n    type: Boolean,\n    required: true,\n  },\n});\n</script>\n\n<style>\n.gm-ui-hover-effect:focus {\n  outline: none !important;\n}\n\n.tooltip-exit-button-mask {\n  height: 15px;\n  width: 15px;\n  background-color: white;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  z-index: 9999;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
