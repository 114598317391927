var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { loader } from '../../legacy/googlemaps-loader';
import { CHAR } from '../../constants';
var sessionToken;
export var autocomplete = function (input, language, locationBias) { return __awaiter(void 0, void 0, Promise, function () {
    var AutocompleteService, service;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loader.importLibrary('places')];
            case 1:
                AutocompleteService = (_a.sent()).AutocompleteService;
                service = new AutocompleteService();
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        if (!sessionToken) {
                            console.log('Creating new session token');
                            sessionToken = new google.maps.places.AutocompleteSessionToken();
                        }
                        console.log('sessionToken', sessionToken);
                        service.getPlacePredictions({
                            input: input,
                            componentRestrictions: { country: 'ca' },
                            language: language,
                            locationBias: locationBias,
                            sessionToken: sessionToken,
                        }, function (results, status) {
                            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                                // "route" type results are not valid addresses in our context
                                var filteredResults = results.filter(function (result) {
                                    return result.types.indexOf('route') === -1;
                                });
                                resolve(filteredResults);
                            }
                            else {
                                reject(status);
                            }
                        });
                    })];
        }
    });
}); };
export var getPlaceDetails = function (placeId, attrContainer) { return __awaiter(void 0, void 0, Promise, function () {
    var PlacesService, service;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loader.importLibrary('places')];
            case 1:
                PlacesService = (_a.sent()).PlacesService;
                service = new PlacesService(attrContainer);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        service.getDetails({
                            placeId: placeId,
                            fields: ['place_id', 'types', 'name', 'address_components', 'geometry', 'formatted_address'],
                            sessionToken: sessionToken,
                        }, function (place, status) {
                            sessionToken = new google.maps.places.AutocompleteSessionToken();
                            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                                resolve(place);
                            }
                            else {
                                reject(status);
                            }
                        });
                    })];
        }
    });
}); };
export var parsePlace = function (placeResult) {
    var _a, _b, _c, _d, _e;
    var place = {
        name: '',
        formattedAddress: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        province: '',
        suburb: '',
        coordinates: {
            lat: null,
            lng: null,
        },
        viewport: null,
        placeDetails: '',
    };
    if (placeResult.address_components === undefined) {
        return null;
    }
    place.placeDetails = JSON.stringify(placeResult);
    // eslint-disable-next-line no-restricted-syntax
    for (var _i = 0, _f = placeResult.address_components; _i < _f.length; _i++) {
        var component = _f[_i];
        var componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                place.streetAddress = "".concat(component.long_name, " ").concat(place.streetAddress);
                break;
            }
            case 'route': {
                place.streetAddress += component.long_name;
                break;
            }
            case 'postal_code': {
                place.postalCode = component.long_name;
                break;
            }
            case 'postal_code_prefix': {
                if (!place.postalCode) {
                    place.postalCode = component.long_name;
                }
                break;
            }
            case 'locality':
                place.city = component.long_name;
                break;
            case 'administrative_area_level_3':
                if (!place.city) {
                    place.city = component.long_name;
                }
                break;
            case 'administrative_area_level_1': {
                place.province = component.short_name;
                break;
            }
            case 'sublocality_level_1': {
                place.suburb = component.long_name;
                break;
            }
            default:
                break;
        }
    }
    place.formattedAddress = placeResult.formatted_address;
    if (placeResult.types) {
        if (placeResult.types.includes('establishment')) {
            place.name = placeResult.name;
            place.streetAddress = "".concat(placeResult.name, " ").concat(CHAR.EN_DASH, " ").concat(place.streetAddress);
            place.formattedAddress = "".concat(placeResult.name, " ").concat(CHAR.EN_DASH, " ").concat(place.formattedAddress);
        }
    }
    if (!place.streetAddress && placeResult.name) {
        place.streetAddress = placeResult.name;
    }
    if (place.suburb) {
        place.streetAddress = "".concat(place.streetAddress, " (").concat(place.suburb, ")");
        place.formattedAddress = "".concat(place.formattedAddress, " (").concat(place.suburb, ")");
    }
    place.coordinates = {
        lat: (_b = (_a = placeResult.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat(),
        lng: (_d = (_c = placeResult.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng(),
    };
    // Optional, nice to have to have a better focus
    if ((_e = placeResult.geometry) === null || _e === void 0 ? void 0 : _e.viewport) {
        place.viewport = placeResult.geometry.viewport;
    }
    return place;
};
