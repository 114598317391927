// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.assignment-table-cell[data-v-261d4794] {
  text-align: left;
  font-weight: 400;
  position: sticky;
  top: 0;
  letter-spacing: -0.02rem;
  font-size: 12px;
  line-height: 1;
  color: #9c9ca0;
  width: 100%;
}
@media only screen and (max-width: 1500px) {
.assignment-table-cell[data-v-261d4794] {
    font-size: 11px;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/assignmentTable/AssignmentTableHeaderCell.vue"],"names":[],"mappings":";AAWA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,MAAM;EACN,wBAAwB;EACxB,eAAe;EACf,cAAc;EACd,cAAc;EACd,WAAW;AACb;AAEA;AACE;IACE,eAAe;AACjB;AACF","sourcesContent":["<template>\n  <div class=\"assignment-table-cell\">{{ title }}</div>\n</template>\n\n<script setup>\ndefineProps({\n  title: { type: String, required: true },\n});\n</script>\n\n<style scoped>\n.assignment-table-cell {\n  text-align: left;\n  font-weight: 400;\n  position: sticky;\n  top: 0;\n  letter-spacing: -0.02rem;\n  font-size: 12px;\n  line-height: 1;\n  color: #9c9ca0;\n  width: 100%;\n}\n\n@media only screen and (max-width: 1500px) {\n  .assignment-table-cell {\n    font-size: 11px;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
