/* istanbul ignore file */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { orderBy } from 'lodash';
import querystring from 'querystring';
import { AXIOS_CONFIG } from '../../constants';
var updateActiveTransportStatus = function (state, isActive) {
    if (state.activeTransportSnapshotId) {
        var transportRequestSnapshot = state.transportsById.get(state.activeTransportSnapshotId);
        if (transportRequestSnapshot) {
            transportRequestSnapshot.isActive = isActive;
            if (transportRequestSnapshot.teamId) {
                var teamSnapshot = state.teamsById.get(transportRequestSnapshot.teamId);
                if (teamSnapshot) {
                    teamSnapshot.isActive = isActive;
                }
            }
        }
    }
};
var module = {
    namespaced: true,
    state: {
        timestamps: [],
        from: undefined,
        to: undefined,
        teams: [],
        teamsById: new Map(),
        transports: [],
        transportsById: new Map(),
        error: undefined,
        activeTransportSnapshotId: undefined,
    },
    mutations: {
        setTimestamps: function (state, timestamps) {
            state.timestamps = timestamps.map(function (timestamp) { return Date.parse(timestamp); });
        },
        setTeams: function (state, teamSnapshots) {
            updateActiveTransportStatus(state, false);
            var teamsById = new Map();
            teamSnapshots.forEach(function (snapshot) {
                var _a, _b;
                snapshot.hasPosition = !!(((_a = snapshot.position) === null || _a === void 0 ? void 0 : _a.lat) && ((_b = snapshot.position) === null || _b === void 0 ? void 0 : _b.lng));
                teamsById.set(snapshot.id, snapshot);
            });
            state.teams = teamSnapshots;
            state.teamsById = teamsById;
            updateActiveTransportStatus(state, true);
        },
        setTransports: function (state, transportSnapshots) {
            updateActiveTransportStatus(state, false);
            var transportsById = new Map();
            transportSnapshots.forEach(function (snapshot) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                snapshot.isPending = snapshot.status === 'PENDING';
                snapshot.hasOriginCoordinates = !!(((_b = (_a = snapshot.origin) === null || _a === void 0 ? void 0 : _a.coordinates) === null || _b === void 0 ? void 0 : _b.lat) && ((_d = (_c = snapshot.origin) === null || _c === void 0 ? void 0 : _c.coordinates) === null || _d === void 0 ? void 0 : _d.lng));
                snapshot.hasDestinationCoordinates = !!(((_f = (_e = snapshot.destination) === null || _e === void 0 ? void 0 : _e.coordinates) === null || _f === void 0 ? void 0 : _f.lat) && ((_h = (_g = snapshot.destination) === null || _g === void 0 ? void 0 : _g.coordinates) === null || _h === void 0 ? void 0 : _h.lng));
                transportsById.set(snapshot.id, snapshot);
            });
            state.transports = orderBy(transportSnapshots, ['isPending', 'createdOn'], ['desc', 'asc']);
            state.transportsById = transportsById;
            updateActiveTransportStatus(state, true);
        },
        setPeriod: function (state, _a) {
            var from = _a.from, to = _a.to;
            state.from = from;
            state.to = to;
        },
        setError: function (state, error) {
            state.error = error;
        },
        setActiveTransportSnapshotId: function (state, activeTransportSnapshotId) {
            updateActiveTransportStatus(state, false);
            state.activeTransportSnapshotId = activeTransportSnapshotId;
            if (activeTransportSnapshotId) {
                updateActiveTransportStatus(state, true);
            }
        },
    },
    actions: {
        fetchTimestamps: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var fromString, toString, url, response, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            commit('setError', undefined);
                            fromString = new Date(state.from).toISOString().substring(0, 16);
                            toString = new Date(state.to).toISOString().substring(0, 16);
                            url = '/app/api/snapshot?'.concat(querystring.stringify({ from: fromString, to: toString }));
                            return [4 /*yield*/, axios.get(url, AXIOS_CONFIG)];
                        case 1:
                            response = _b.sent();
                            if (response.status < 400) {
                                commit('setTimestamps', response.data);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _b.sent();
                            commit('setTimestamps', []);
                            commit('setError', err_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        fetchTimestamp: function (_a, timestamp) {
            var commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                var url, response, _b, teams, transportRequests, err_2;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!timestamp) {
                                commit('setTeams', []);
                                commit('setTransports', []);
                                return [2 /*return*/];
                            }
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 3, , 4]);
                            commit('setError', undefined);
                            url = '/app/api/snapshot/'.concat(new Date(timestamp).toISOString());
                            return [4 /*yield*/, axios.get(url, AXIOS_CONFIG)];
                        case 2:
                            response = _c.sent();
                            if (response.status < 400) {
                                _b = response.data, teams = _b.teams, transportRequests = _b.transportRequests;
                                commit('setTeams', teams !== null && teams !== void 0 ? teams : []);
                                commit('setTransports', transportRequests !== null && transportRequests !== void 0 ? transportRequests : []);
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            err_2 = _c.sent();
                            commit('setError', err_2);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        reset: function (_a) {
            var commit = _a.commit;
            commit('setActiveTransportSnapshotId', null);
            commit('setTimestamps', []);
            commit('setTeams', []);
            commit('setTransports', []);
            commit('setPeriod', {});
        },
        resetContent: function (_a) {
            var commit = _a.commit;
            commit('setActiveTransportSnapshotId', null);
            commit('setTeams', []);
            commit('setTransports', []);
        },
        submitPeriod: function (_a, period) {
            var dispatch = _a.dispatch, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setPeriod', period);
                            return [4 /*yield*/, dispatch('fetchTimestamps')];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        toggleActiveTransportSnapshotId: function (_a, value) {
            var commit = _a.commit, state = _a.state;
            if (state.activeTransportSnapshotId !== value) {
                commit('setActiveTransportSnapshotId', value);
            }
            else {
                commit('setActiveTransportSnapshotId', null);
            }
        },
    },
};
export default module;
