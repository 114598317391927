// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.assignment-subheader[data-v-372761ae] {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/assignment/AssignmentSubHeader.vue"],"names":[],"mappings":";AAiBA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC","sourcesContent":["<template>\n  <div class=\"assignment-subheader\">\n    <h5>{{ label }}</h5>\n    <slot></slot>\n  </div>\n</template>\n\n<script setup>\ndefineProps({\n  label: {\n    type: String,\n    required: true,\n  },\n});\n</script>\n\n<style scoped>\n.assignment-subheader {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
