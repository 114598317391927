var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, h, ref } from 'vue';
import { autocomplete, getPlaceDetails, parsePlace } from './place';
import { NAutoComplete } from 'naive-ui';
export default defineComponent({
    components: { NAutoComplete: NAutoComplete },
    props: {
        dataTestid: {
            type: String,
            required: true,
        },
        map: {
            type: Object,
            default: null,
        },
        bounds: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    emits: ['placeChange'],
    setup: function (props, context) {
        var searchQuery = ref('');
        var results = ref([]);
        var options = ref([]);
        var attrContainer = ref(null);
        function handleAutoComplete() {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!searchQuery.value) {
                                return [2 /*return*/];
                            }
                            _a = results;
                            return [4 /*yield*/, autocomplete(searchQuery.value, 'fr-CA', props.bounds)];
                        case 1:
                            _a.value = _b.sent();
                            options.value = results.value.map(function (result) { return ({
                                label: result.description,
                                value: result.place_id,
                            }); });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function renderLabel(option) {
            var prediction = results.value.find(function (result) { return result.place_id === option.value; });
            if (!prediction) {
                return '';
            }
            return [
                h('span', { class: 'autocomplete-main-text' }, prediction.structured_formatting.main_text),
                ' ',
                h('span', { class: 'autocomplete-secondary-text' }, prediction.structured_formatting.secondary_text),
            ];
        }
        function placeChanged(placeId) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var placePrediction, newPlace, place;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            placePrediction = results.value.find(function (result) { return result.place_id === placeId; });
                            if (!placePrediction) {
                                return [2 /*return*/];
                            }
                            searchQuery.value = placePrediction.description;
                            return [4 /*yield*/, getPlaceDetails(placePrediction.place_id, (_a = props.map) !== null && _a !== void 0 ? _a : attrContainer.value)];
                        case 1:
                            newPlace = _b.sent();
                            if (!newPlace) {
                                context.emit('placeChange', null);
                                return [2 /*return*/];
                            }
                            else {
                                place = parsePlace(newPlace);
                                context.emit('placeChange', place);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        return {
            attrContainer: attrContainer,
            handleAutoComplete: handleAutoComplete,
            options: options,
            placeChanged: placeChanged,
            renderLabel: renderLabel,
            results: results,
            searchQuery: searchQuery,
        };
    },
});
