<template>
  <n-config-provider :theme-overrides="naiveThemeConfig" class="container">
    <MapHeader />
    <MapForm @refresh="resumeFitAndPanToBounds" />
    <div class="pad-wrapper">
      <MapView @refresh-stores="refreshStores" />
    </div>
  </n-config-provider>
</template>

<script>
import { mapMutations, useStore } from 'vuex';
import { NConfigProvider } from 'naive-ui';

import naiveThemeConfig from '../../config/naive-theme-config.json';
import { themeConfigAddendum } from '../../config/naive-ui';

import { key } from '../../store';

import MapView from './MapView.vue';
import MapForm from './MapForm.vue';
import MapHeader from './MapHeader.vue';

export default {
  components: {
    MapView,
    MapForm,
    MapHeader,
    NConfigProvider,
  },
  setup() {
    const { state } = useStore(key);

    return {
      teams: state.teams.data,
      transports: state.transports.data,
      naiveThemeConfig: {
        ...naiveThemeConfig,
        ...themeConfigAddendum,
      },
    };
  },
  async mounted() {
    this.$store.dispatch('transports/resetFilter');
    await this.refreshStores();
    this.setPolling();
  },
  beforeUnmount() {
    this.clearPolling();
  },
  methods: {
    ...mapMutations({
      setFitAndPanToBounds: 'map/setFitAndPanToBounds',
    }),
    async refreshStores() {
      return Promise.all([this.$store.dispatch('teams/fetch'), this.$store.dispatch('transports/fetch')]);
    },
    clearPolling() {
      clearInterval(this.interval);
    },
    setPolling() {
      this.interval = setInterval(() => this.refreshStores(), 15000);
    },
    resumeFitAndPanToBounds() {
      this.setFitAndPanToBounds(true);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pad-wrapper {
  padding: 0 25px;
}
</style>
