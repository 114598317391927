import { CHAR } from '../constants';

const METERS_PER_KILOMETER = 1000;
const MILLISECONDS_PER_SECOND = 1000;

export const formatStandardAddress = (street, city) => [street, city].filter((item) => item).join(', ');

export const formatInstitutionAddress = (institutionName, street, city) =>
  `${institutionName} ${CHAR.EN_DASH} ${formatStandardAddress(street, city)}`;

export const formatAddress = (institutionName, street, city) =>
  institutionName ? formatInstitutionAddress(institutionName, street, city) : formatStandardAddress(street, city);

export const formatName = (firstName, lastName) => [firstName, lastName].filter((item) => item).join(' ');

export const formatCoordinates = (coords) => {
  if (!coords) {
    return '[?, ?]';
  }
  return `[${coords.lat ?? '?'}, ${coords.lng ?? '?'}]`;
};

export const formatNumber = (number, localeValue) => new Intl.NumberFormat(localeValue).format(number);

export const formatDate = (date, localeValue) =>
  new Intl.DateTimeFormat(localeValue, { day: 'numeric', month: 'long', year: 'numeric' }).format(date);

export const formatVehicle = (manufacturer, model, color) => [manufacturer, model, color].join(' - ');

export const formatMilliseconds = (milliseconds) => `${(milliseconds / MILLISECONDS_PER_SECOND).toFixed(0)} sec`;

const distanceIsGreaterThanOrEqualTo50Meters = (distanceInMeters) => distanceInMeters && distanceInMeters >= 50;

const formatDistanceInKilometers = (distanceInMeters, localeValue) => {
  const distanceFormattingOptions = { minimumFractionDigits: 1, maximumFractionDigits: 1 };
  return distanceIsGreaterThanOrEqualTo50Meters(distanceInMeters)
    ? (distanceInMeters / METERS_PER_KILOMETER).toLocaleString(localeValue, distanceFormattingOptions)
    : '0';
};

const formatDurationInMinutes = (durationInSeconds) => {
  const secondsPerMinute = 60;
  return Math.round(durationInSeconds / secondsPerMinute);
};

export const formatRouteInformationDistance = (routeInformation, localeValue) => {
  const distanceInKilometers = formatDistanceInKilometers(routeInformation.distanceInMeters, localeValue);
  return `${distanceInKilometers} km`;
};

export const formatRouteInformationTime = (routeInformation) => {
  const durationInMinutes = formatDurationInMinutes(routeInformation.durationInSeconds);
  return `${durationInMinutes} min`;
};

export const formatNotAvailable = (localeValue) => {
  const isLocaleFrench = localeValue === 'fr';
  return isLocaleFrench ? 'N/D' : 'N/A';
};
