<template>
  <div v-if="hasPlace()" class="address-card alert alert-info">
    <div v-if="img != ''" class="image">
      <img :src="img" />
    </div>
    <div class="card-info">
      <span v-if="place.streetAddress" :id="id" class="title">{{ place.streetAddress }}</span>
      <span v-if="place.city">{{ cityName }}</span>
      <span v-if="place.province">{{ place.province }}</span>
      <span v-if="place.postalCode">{{ place.postalCode }}</span>
    </div>
    <div v-if="!readOnly" class="close-button">
      <button type="button" class="close" @click="clear">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.59099 7.46966L13.0607 1.99999L12 0.939331L6.53033 6.409L1.06067 0.939331L8.79169e-06 1.99999L5.46967 7.46966L0 12.9393L1.06066 14L6.53033 8.53032L12 14L13.0607 12.9393L7.59099 7.46966Z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    place: {
      type: Object,
      required: true,
    },
    img: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: () => {
        try {
          return crypto.randomUUID();
        } catch (e) {
          return String(new Date().getTime());
        }
      },
    },
  },
  emits: ['clear'],
  setup(props, context) {
    const cityName = computed(() => {
      if (props.place.province || props.place.postalCode) {
        return String(props.place.city).concat(',');
      }
      return props.place.city;
    });

    function hasPlace() {
      return props.place && (!!props.place.streetAddress || !!props.place.city);
    }

    function clear() {
      context.emit('clear');
    }

    return {
      cityName,
      clear,
      hasPlace,
    };
  },
});
</script>

<style scoped>
.address-card {
  display: flex;
  flex: 1;
}

.image {
  margin-right: 5px;
  flex-grow: 0;
}

.card-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
}

.card-info > span:not(:last-of-type) {
  margin-right: 0.32rem;
}

.close-button {
  flex-grow: 0;
}

.address-card span {
  display: block;
}

span.title {
  font-weight: bold;
  flex-basis: 100%;
}

.alert {
  margin: 10px 0px;
}
</style>
