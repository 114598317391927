import ExcelColumnName from 'excel-column-name';
import _isNil from 'lodash/isNil';
import TeamStatus from '../components/team/TeamStatus';
/**
 * @param {Number} id
 * @return {String}
 */
export var transportIdToLetters = function (id) {
    if (_isNil(id) || Number.isNaN(Number(id)) || id < 0) {
        return '?';
    }
    // Will give us A to ZZ max: (26*26+26 = 702)
    var n = (id % 702) + 1;
    return ExcelColumnName.intToExcelCol(n);
};
/**
 * @param {String} teamStatus
 * @returns {string} colorName
 */
export var teamStatusToMarkerColorName = function (teamStatus) {
    if (teamStatus === TeamStatus.ASSIGNED) {
        return 'green';
    }
    if (teamStatus === TeamStatus.IN_RETURN) {
        return 'orange';
    }
    if (teamStatus.includes(TeamStatus.AVAILABLE)) {
        return 'blue';
    }
    if (teamStatus === TeamStatus.ON_BREAK) {
        return 'red';
    }
    return 'gray';
};
/**
 * @param {String} teamStatus
 * @returns {String} teamIconName
 */
export var teamStatusToIconName = function (teamStatus) { return "".concat(teamStatusToMarkerColorName(teamStatus), "-car"); };
/**
 * @param {String} teamStatus
 * @returns {String} vipTeamIconName
 */
export var vipTeamStatusToIconName = function (teamStatus) {
    var VIP_SUFFIX = '-yellow-outline';
    return "".concat(teamStatusToMarkerColorName(teamStatus), "-car").concat(VIP_SUFFIX);
};
/**
 * @param {Number} milliseconds
 * @returns {Number} minutes
 */
export var millisecondsToMinutes = function (milliseconds) {
    var millisecondsInASecond = 1000;
    var secondsInAMinute = 60;
    return Math.floor(milliseconds / millisecondsInASecond / secondsInAMinute);
};
export var reassignResponseDtoToPartialTransportObject = function (data) { return ({
    transportRequestId: data.transportRequest.id,
    status: data.transportRequest.status,
}); };
var teamHasPosition = function (team) { var _a, _b; return ((_a = team === null || team === void 0 ? void 0 : team.position) === null || _a === void 0 ? void 0 : _a.lat) && ((_b = team === null || team === void 0 ? void 0 : team.position) === null || _b === void 0 ? void 0 : _b.lng); };
var getTeamPosition = function (team) { return team.position; };
export var teamsToCoordinates = function (teams) { return teams.filter(teamHasPosition).map(getTeamPosition); };
var transportHasCoordinates = function (transport) { var _a, _b; return ((_a = transport === null || transport === void 0 ? void 0 : transport.coordinates) === null || _a === void 0 ? void 0 : _a.lat) && ((_b = transport === null || transport === void 0 ? void 0 : transport.coordinates) === null || _b === void 0 ? void 0 : _b.lng); };
var transportToCoordinates = function (transport) { return transport.coordinates; };
export var transportsToCoordinates = function (transports) {
    return transports.filter(transportHasCoordinates).map(transportToCoordinates);
};
