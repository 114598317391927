/* istanbul ignore file */

const TeamStatus = {
  NOT_DISSOLVED: 'NOT_DISSOLVED',
  ASSIGNED: 'ASSIGNED',
  IN_RETURN: 'IN_RETURN',
  AVAILABLE: 'AVAILABLE',
  ON_BREAK: 'ON_BREAK',
};

export const TeamAvailableStatus = {
  AVAILABLE_IN_LOCATION: 'AVAILABLE_IN_LOCATION',
  AVAILABLE_IN_INSTITUTION: 'AVAILABLE_IN_INSTITUTION',
  AVAILABLE_IN_REGION: 'AVAILABLE_IN_REGION',
  AVAILABLE_AT_COORDINATES: 'AVAILABLE_AT_COORDINATES',
};

export default TeamStatus;
