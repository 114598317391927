<template>
  <div class="flex-wrapper">
    <div class="header-group">
      <h3 class="title">
        {{ t('assignment.title') }}
      </h3>

      <PiloteProgressTag v-if="timer" :percentage="timer.percentage.value">
        <div class="progress-tag-label">{{ formatMilliseconds(timer.timeInMs.value) }}</div>
      </PiloteProgressTag>
    </div>

    <div class="date">{{ currentDate }}</div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { formatDate, formatMilliseconds } from '../../helpers/Formatters';
import PiloteProgressTag from '../generic/progress/PiloteProgressTag.vue';

defineProps({
  timer: {
    type: Object,
    required: false,
    default: null,
  },
});

const { t, locale } = useI18n({ useScope: 'global' });
const currentDate = formatDate(Date.now(), locale.value);
</script>

<style scoped>
.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(65% - 15px);
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.date {
  color: #9c9ca0;
  font-size: 15px;
}
</style>
