// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.progress-icon[data-v-4462e7d6] {
  margin-right: 8px;
  margin-bottom: 8px;
  align-self: center;
}
.progress-tag[data-v-4462e7d6] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 100px;
  background-color: #d9edf7;
  color: #152943;
  border-radius: 20px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/progress/PiloteProgressTag.vue"],"names":[],"mappings":";AAmBA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["<template>\n  <div class=\"progress-tag\">\n    <PiloteProgress :percentage=\"percentage\" class=\"progress-icon\" />\n    <slot></slot>\n  </div>\n</template>\n\n<script setup>\nimport PiloteProgress from './PiloteProgress.vue';\n\ndefineProps({\n  percentage: {\n    type: Number,\n    required: true,\n  },\n});\n</script>\n\n<style scoped>\n.progress-icon {\n  margin-right: 8px;\n  margin-bottom: 8px;\n  align-self: center;\n}\n\n.progress-tag {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  width: 100px;\n  background-color: #d9edf7;\n  color: #152943;\n  border-radius: 20px;\n  font-weight: bold;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
