<template>
  <n-select v-model:value="selectedValues" :placeholder="placeholder" :options="options" :multiple="true" />
</template>

<script setup>
import { ref } from 'vue';
import { NSelect } from 'naive-ui';

const props = defineProps({
  initialValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: () => '',
  },
});

const selectedValues = ref([props.initialValue]);
</script>

<style scoped>
:deep(.n-tag) {
  background-color: #e2e1e6;
  height: 22px;
  margin: auto;
}

:deep(.n-base-selection) {
  border-radius: 3px;
}

:deep(.n-base-suffix__arrow) {
  border-left: 1px solid #b3b3b9;
  height: 16px;
  padding-left: 3px;
}
</style>
