var ELECTRIC_MARKER_FOREGROUND_FILL_COLOR = "#000";
var ELECTRIC_MARKER_FOREGROUND_FILL_OPACITY = 1;
var ELECTRIC_MARKER_FOREGROUND_STROKE_WEIGHT = 0;
var ELECTRIC_MARKER_BACKGROUND_FILL_COLOR = "#fff";
var ELECTRIC_MARKER_BACKGROUND_FILL_OPACITY = 1;
var ELECTRIC_MARKER_BACKGROUND_STROKE_COLOR = "#000";
var ELECTRIC_MARKER_BACKGROUND_STROKE_WEIGHT = 1;
var DELTA_BETWEEN_ELECTRIC_FOREGROUND_AND_BACKGROUND = { x: 3.5, y: 1.5 };
var electricMarkerForegroundPathWithOffset = function (offset) { return "\n  M ".concat(offset.x, " ").concat(offset.y, "\n  c -0.079,-0.153,-0.238,-0.248,-0.411,-0.248\n  h -3.034l0.985,-2.463\n  c 0.058,-0.142,0.04,-0.305,-0.046,-0.433\n  c -0.087,-0.128,-0.247,-0.204,-0.385,-0.204\n  h -3.41\n  c -0.233,0,-0.43,0.173,-0.46,0.404l-0.62,4.65\n  c -0.019,0.133,0.022,0.266,0.11,0.367\n  c 0.089,0.101,0.216,0.159,0.35,0.159\n  h 2.835l-0.81,3.761\n  c -0.046,0.217,0.068,0.437,0.272,0.525\n  c 0.059,0.042,0.121,0.054,0.183,0.054\n  c 0.15,0,0.295,-0.073,0.384,-0.202l4.03,-5.89\n  c 0.097,-0.143,0.108,-0.327,0.027,-0.48\n  z\n"); };
var electricMarkerBackgroundPathWithOffset = function (offset) {
    var cumulativeOffset = {
        x: offset.x + DELTA_BETWEEN_ELECTRIC_FOREGROUND_AND_BACKGROUND.x,
        y: offset.y + DELTA_BETWEEN_ELECTRIC_FOREGROUND_AND_BACKGROUND.y
    };
    return "\n    M ".concat(cumulativeOffset.x, " ").concat(cumulativeOffset.y, "\n    c 0,4.175,-3.385,7.56,-7.56,7.56\n    c -4.175,0,-7.56,-3.385,-7.56,-7.56\n    c 0,-4.175,3.385,-7.56,7.56,-7.56\n    c 4.175,0,7.56,3.385,7.56,7.56\n    z\n  ");
};
var useElectricMarkerSpecs = function (offset) {
    return {
        background: {
            path: electricMarkerBackgroundPathWithOffset(offset),
            fillColor: ELECTRIC_MARKER_BACKGROUND_FILL_COLOR,
            fillOpacity: ELECTRIC_MARKER_BACKGROUND_FILL_OPACITY,
            strokeWeight: ELECTRIC_MARKER_BACKGROUND_STROKE_WEIGHT,
            strokeColor: ELECTRIC_MARKER_BACKGROUND_STROKE_COLOR,
        },
        foreground: {
            path: electricMarkerForegroundPathWithOffset(offset),
            fillColor: ELECTRIC_MARKER_FOREGROUND_FILL_COLOR,
            fillOpacity: ELECTRIC_MARKER_FOREGROUND_FILL_OPACITY,
            strokeWeight: ELECTRIC_MARKER_FOREGROUND_STROKE_WEIGHT,
        },
    };
};
export default useElectricMarkerSpecs;
