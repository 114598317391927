/* istanbul ignore file */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import axios from 'axios';
import querystring from 'querystring';
import TeamStatus from '../../components/team/TeamStatus';
import { AXIOS_CONFIG, HTTP_STATUS_CODE } from '../../constants';
import { TEAM_STATUS_KEY, FILTER_TEAM_NOT_DISSOLVED } from '../collections/teamQueryFields';
var TEAM_PATH = '/app/api/team';
var initialFilterState = (_a = {},
    _a[TEAM_STATUS_KEY] = FILTER_TEAM_NOT_DISSOLVED,
    _a);
var availableTeams = function (teams) { return teams.filter(function (team) { return team.status.includes('AVAILABLE'); }); };
var assignedTeams = function (teams) { return teams.filter(function (team) { return team.status === TeamStatus.ASSIGNED; }); };
var hasPosition = function (team) { var _a, _b; return ((_a = team === null || team === void 0 ? void 0 : team.position) === null || _a === void 0 ? void 0 : _a.lat) && ((_b = team === null || team === void 0 ? void 0 : team.position) === null || _b === void 0 ? void 0 : _b.lng); };
var positionedTeams = function (teams) { return teams.filter(function (team) { return hasPosition(team); }); };
var unpositionedTeams = function (teams) { return teams.filter(function (team) { return !hasPosition(team); }); };
var findTeamById = function (state, teamId) {
    var team = state.data.find(function (t) { return t.id === teamId; });
    if (!team) {
        throw new Error("Team with id ".concat(teamId, " was not found."));
    }
    return team;
};
var getAssistedAssignmentToggleRequestPath = function (team) {
    var requestPathSuffix = team.isAssistedAssignmentEnabled ? 'disable' : 'enable';
    return "".concat(TEAM_PATH, "/").concat(team.id, "/assisted_assignment/").concat(requestPathSuffix);
};
var setAssistedAssignmentEnabledForTeam = function (_a, _b) {
    var commit = _a.commit, state = _a.state;
    var teamId = _b.teamId, isAssistedAssignmentEnabled = _b.isAssistedAssignmentEnabled;
    return __awaiter(void 0, void 0, void 0, function () {
        var existingTeam, requestPath, response, updatedTeam_1, otherTeams, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    existingTeam = findTeamById(state, teamId);
                    requestPath = getAssistedAssignmentToggleRequestPath(existingTeam);
                    return [4 /*yield*/, axios.post(requestPath, {}, AXIOS_CONFIG)];
                case 1:
                    response = _c.sent();
                    if (response.status === 204) {
                        updatedTeam_1 = __assign(__assign({}, existingTeam), { isAssistedAssignmentEnabled: isAssistedAssignmentEnabled });
                        otherTeams = state.data.filter(function (team) { return team.id !== updatedTeam_1.id; });
                        commit('setTeams', __spreadArray([updatedTeam_1], otherTeams, true));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    commit('setError', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
var module = {
    namespaced: true,
    state: {
        data: [],
        filter: __assign({}, initialFilterState),
        error: undefined,
    },
    mutations: {
        resetFilter: function (state) {
            state.filter = __assign({}, initialFilterState);
        },
        setTeams: function (state, teams) {
            state.data = teams;
        },
        setTeamStatus: function (state, team) {
            var teamToUpdate = state.data.find(function (t) { return t.id === team.id; });
            if (teamToUpdate) {
                var otherTeams = state.data.filter(function (t) { return t.id !== teamToUpdate.id; });
                var updatedTeam = __assign(__assign({}, teamToUpdate), { status: team.status });
                state.data = __spreadArray(__spreadArray([], otherTeams, true), [updatedTeam], false);
            }
        },
        setFilter: function (state, filter) {
            state.filter = filter;
        },
        setError: function (state, error) {
            state.error = error;
        },
    },
    actions: {
        fetch: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var response, error_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            commit('setError', undefined);
                            return [4 /*yield*/, axios.post('/app/api/team', querystring.stringify(state.filter), AXIOS_CONFIG)];
                        case 1:
                            response = _b.sent();
                            if (response.status < HTTP_STATUS_CODE.BAD_REQUEST) {
                                commit('setTeams', response.data);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _b.sent();
                            commit('setError', error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        resetFilter: function (_a) {
            var commit = _a.commit;
            commit('resetFilter');
        },
        setFilter: function (_a, filter) {
            var commit = _a.commit;
            commit('setFilter', filter);
        },
        submitFilter: function (_a, filter) {
            var dispatch = _a.dispatch, commit = _a.commit;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setFilter', filter);
                            return [4 /*yield*/, dispatch('fetch')];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        enableAssistedAssignmentForTeam: function (store, teamId) {
            return __awaiter(this, void 0, void 0, function () {
                var options;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            options = { teamId: teamId, isAssistedAssignmentEnabled: true };
                            return [4 /*yield*/, setAssistedAssignmentEnabledForTeam(store, options)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        disableAssistedAssignmentForTeam: function (store, teamId) {
            return __awaiter(this, void 0, void 0, function () {
                var options;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            options = { teamId: teamId, isAssistedAssignmentEnabled: false };
                            return [4 /*yield*/, setAssistedAssignmentEnabledForTeam(store, options)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    getters: {
        positionedAssignedOrAvailable: function (state) { return positionedTeams(__spreadArray(__spreadArray([], availableTeams(state.data), true), assignedTeams(state.data), true)); },
        getTeamById: function (state) { return function (id) { return state.data.find(function (team) { return team.id === id; }); }; },
        unpositionedAssignedOrAvailable: function (state) { return unpositionedTeams(__spreadArray(__spreadArray([], availableTeams(state.data), true), assignedTeams(state.data), true)); },
    },
};
export default module;
